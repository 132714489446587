/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
function importImage(image) {
  let imageUrl;
  try {
    imageUrl = require(`./${process.env.GATSBY_LOCALE}/${image}`);
  } catch {
    try {
      imageUrl = require(`./default/${image}`);
    } catch {
      imageUrl = undefined;
    }
  }
  return imageUrl;
}

function importIcon(icon) {
  let iconUrl;
  try {
    iconUrl = require(`./${process.env.GATSBY_LOCALE}/icons/${icon}`);
  } catch {
    try {
      iconUrl = require(`./default/icons/${icon}`);
    } catch {
      iconUrl = undefined;
    }
  }
  return iconUrl;
}

const VideoThumbnail = importImage('kokolingo_video.jpg');
const shareCover = importImage('share-cover.png');
const qrAppLogin = importImage('qr-kokolingo-app-login.png');

const testimonial1 = importImage('testimonials/1.jpg');
const testimonial2 = importImage('testimonials/2.jpg');
const testimonial3 = importImage('testimonials/3.jpg');
const testimonial4 = importImage('testimonials/4.jpg');
const testimonial5 = importImage('testimonials/5.jpg');
const testimonial6 = importImage('testimonials/6.jpg');
const testimonial7 = importImage('testimonials/7.jpg');
const testimonial8 = importImage('testimonials/8.jpg');

const camera = importIcon('camera.svg');
const checklistTime = importIcon('checklist-time.svg');
const checklistTime2 = importIcon('checklist-time2.svg');
const close = importIcon('close.svg');
const devices = importIcon('devices.svg');
const downloadPDF = importIcon('download-pdf.svg');
const fb = importIcon('fb.svg');
const home = importIcon('home.svg');
const home2 = importIcon('home2.svg');
const ig = importIcon('ig.svg');
const like = importIcon('like.svg');
const like2 = importIcon('like2.svg');
const minus = importIcon('minus.svg');
const minus2 = importIcon('minus2.svg');
const open = importIcon('open.svg');
const play = importIcon('play.svg');
const plus = importIcon('plus.svg');
const plus2 = importIcon('plus2.svg');
const puzzle = importIcon('puzzle.svg');
const tw = importIcon('tw.svg');
const yt = importIcon('yt.svg');

const icons = {
  camera,
  checklistTime,
  checklistTime2,
  close,
  devices,
  downloadPDF,
  fb,
  home,
  home2,
  ig,
  like,
  like2,
  minus,
  minus2,
  open,
  play,
  plus,
  plus2,
  puzzle,
  tw,
  yt,
};

export {
  VideoThumbnail,
  shareCover,
  qrAppLogin,
  testimonial1,
  testimonial2,
  testimonial3,
  testimonial4,
  testimonial5,
  testimonial6,
  testimonial7,
  testimonial8,
  icons,
};
