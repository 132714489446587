import React from 'react';

export default () => (
  <div
    className="container"
    dangerouslySetInnerHTML={{
      __html: `
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:Wingdings;
\tpanose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:0cm;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
\t{mso-style-link:"Footer Char";
\tmargin:0cm;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
\t{color:#0563C1;
\ttext-decoration:underline;}
p
\t{margin-right:0cm;
\tmargin-left:0cm;
\tfont-size:12.0pt;
\tfont-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:0cm;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:0cm;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
span.FooterChar
\t{mso-style-name:"Footer Char";
\tmso-style-link:Footer;}
.MsoChpDefault
\t{font-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{margin-bottom:8.0pt;
\tline-height:107%;}
 /* Page Definitions */
 @page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 ol
\t{margin-bottom:0cm;}
ul
\t{margin-bottom:0cm;}
-->
</style>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-top:0cm;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>REGULAMIN
ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ PRZEZ <br>
ATABI KATARZYNA ŁUSZCZAK</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>(ZWANY DALEJ REGULAMINEM)</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Niniejszy
Regulamin został wydany i wprowadzony w realizacji postanowienia art. 8 ust. 1
ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną przez
Katarzynę Łuszczak, prowadzącą jednoosobową działalność gospodarczą osoby
fizycznej pod firmą ATABI Katarzyna Łuszczak w Poznaniu (61-288), pod adresem:
os. Czecha 52/2 i określa, <br>
w szczególności: rodzaje i zakres usług świadczonych drogą elektroniczną,
warunki ich świadczenia, w tym wymagania techniczne oraz zakazy dostarczania
treści bezprawnych, warunki zawierania i rozwiązywania umów o świadczenie usług
drogą elektroniczną, tryb postępowania reklamacyjnego oraz warunki przesyłania
informacji handlowej.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><b><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>§ 1</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>Postanowienia ogólne</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif'>Definicje pojęć użytych w Regulaminie: </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif'>Ustawa – </span></b><span lang=PL
style='font-family:"Arial",sans-serif'>ustawa o świadczeniu usług drogą
elektroniczną z dnia 18 lipca 2002 roku,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif'>RODO </span></b><span lang=PL
style='font-family:"Arial",sans-serif'>- Ro<span style='color:black;background:
white'>zporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia <br>
27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
oraz uchylenia dyrektywy 95/46/WE,</span></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>System
teleinformatyczny</span></b><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'> - zespół współpracujących ze sobą urządzeń
informatycznych i oprogramowania, zapewniający przetwarzanie i przechowywanie, <br>
a także wysyłanie i odbieranie danych poprzez sieci telekomunikacyjne za pomocą
właściwego dla danego rodzaju sieci urządzenia końcowego w rozumieniu prawa
telekomunikacyjnego,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Świadczenie
usług drogą e</span></b><b><span lang=PL style='font-family:"Arial",sans-serif'>lektroniczną
- </span></b><span lang=PL style='font-family:"Arial",sans-serif;color:black;
background:white'>wykonanie usługi, które następuje przez wysyłanie i
odbieranie danych za pomocą systemów teleinformatycznych, na indywidualne
żądanie Usługobiorcy lub Użytkownika, bez jednoczesnej obecności stron, przy
czym dane te są transmitowane za pośrednictwem sieci publicznych w rozumieniu
prawa telekomunikacyjnego,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Środki
komunikacji elektronicznej</span></b><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'> - rozwiązania techniczne, w tym urządzenia
teleinformatyczne i współpracujące z nimi narzędzia programowe, umożliwiające
indywidualne porozumiewanie się na odległość przy wykorzystaniu transmisji
danych między systemami teleinformatycznymi, a w szczególności poczta
elektroniczna,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Specyfikacja
techniczna</span></b><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'> - zbiór informacji o systemie teleinformatycznym
Usługodawcy oraz wymaganiach technicznych niezbędnych do współpracy z tym
systemem,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>g)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Usługodawca</span></b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'> –
Katarzyna Łuszczak, prowadząca jednoosobową działalność gospodarczą pod firmą
ATABI Katarzyna Łuszczak z siedzibą w Poznaniu (62-288), <br>
os. Czecha 52/2, NIP: 7822209209, REGON: 363904111,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>h)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Usługobiorca</span></b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'> -
podmiot korzystający z usług Usługodawcy, który jest stroną umowy <br>
o świadczeniu usług drogą elektroniczną i/lub który zamówi świadczenie
określonych usług drogą elektroniczną na zasadach poniżej określonych, będący osobą
fizyczną, osobą prawną albo jednostką organizacyjną nie posiadającą osobowości
prawnej, który zobowiąże się do przestrzegania niniejszego Regulaminu,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Użytkownik
</span></b><b><span lang=PL style='font-family:"Arial",sans-serif'>– </span></b><span
lang=PL style='font-family:"Arial",sans-serif'>wskazana przez Usługobiorcę osoba
fizyczna, uprawniona przez Usługobiorcę do korzystania z Konta Usługobiorcy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>j)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Usługa
</span></b><span lang=PL style='font-family:"Arial",sans-serif'>– usługa
świadczona drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy w ramach
Serwisu,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>k)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Serwis
</span></b><span lang=PL style='font-family:"Arial",sans-serif'>– serwis
internetowy znajdujący się pod adresem strony internetowej </span><span
lang=PL><a href="http://www.kokolingo.pl"><span style='font-family:"Arial",sans-serif'>www.kokolingo.pl</span></a></span><span
lang=PL style='font-family:"Arial",sans-serif'>, za którego pośrednictwem
Usługodawca świadczy Usługi na rzecz Usługobiorcy<b>,</b></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>l)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif'>Profil Użytkownika</span></b><span
lang=PL style='font-family:"Arial",sans-serif'> – funkcjonalność w Koncie
Usługobiorcy nie będącego konsumentem, pozwalająca na gromadzenie wybranych
informacji dotyczących aktywności Użytkownika i jego postępów,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>m)<span
style='font:7.0pt "Times New Roman"'> </span></span><strong><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Umowa</span></strong><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>&nbsp;–
umowa zawierana pomiędzy Usługodawcą a Usługobiorcą o świadczenie usług drogą
elektroniczną,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>n)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Informacja
handlowa</span></b><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'> - każda informacja przeznaczona bezpośrednio lub
pośrednio do promowania usług i wizerunku Usługodawcy, dostępna w Serwisie, z
wyłączaniem informacji umożliwiającej porozumiewanie się za pomocą środków
komunikacji elektronicznej z określoną osobą oraz informacji o usługach nie
służącej osiągnięciu efektu handlowego pożądanego przez Usługodawcę. Informacje
handlowe dostępne w Serwisie nie stanowią oferty w rozumieniu art. 66 i
następnych ustawy z dnia 23 kwietnia 1964 roku - Kodeks cywilny.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif'>Usługobiorca zobowiązany jest zapoznać
się z treścią i stosować do postanowień niniejszego Regulaminu, począwszy od
momentu podjęcia pierwszej czynności zmierzającej do skorzystania z Usług
świadczonych przez Usługodawcę w ramach Serwisu lub zmierzającej do innego
skorzystania z Serwisu. Poprzez odznaczenie odpowiedniego pola <i>(„check
box’u”)</i> podczas składania zamówienia na Usługę, Usługobiorca wyraża zgodę
na przestrzeganie postanowień niniejszego Regulaminu, co oznacza pełną
akceptację jego warunków.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>§ 2</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>Rodzaj i zakres Usług świadczonych przez
Usługodawcę</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Usługodawca,
za pomocą Serwisu, świadczy następujące Usługi:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif'>usługę formularza kontaktowego</span></b><span
lang=PL style='font-family:"Arial",sans-serif'> – jest to Usługa polegająca na
umożliwieniu Usługobiorcy nawiązania kontaktu z Usługodawcą za pomocą
dedykowanego adresu <br>
e-mail Usługodawcy. Korzystanie z usługi formularza kontaktowego jest
bezpłatne,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span lang=PL
style='font-family:"Arial",sans-serif'>usługę Konta Usługobiorcy</span></b><span
lang=PL style='font-family:"Arial",sans-serif'> – jest to Usługa polegająca na
umożliwieniu Usługobiorcy posiadanie konta w Serwisie. Założenie Konta
Usługobiorcy jest bezpłatne,</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
lang=PL style='font-family:"Arial",sans-serif'>usługę dostępu do treści
cyfrowych</span></b><span lang=PL style='font-family:"Arial",sans-serif'> –
jest to Usługa polegająca na umożliwieniu Usługobiorcy płatnego dostępu do
treści cyfrowych zamieszczonych w Serwisie.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>§ 3</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>Warunki świadczenia Usług drogą
elektroniczną</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif'>Celem skorzystania z Usługi,
Usługobiorca winien spełniać następujące wymagania techniczne (Środki
komunikacji elektronicznej), niezbędne do współpracy z Systemem
teleinformatycznym Usługodawcy:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif'>posiadać podłączenie do Internetu,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif'>posiadać dostęp do przeglądarki
internetowej umożliwiającej wyświetlanie na monitorze komputera Usługobiorcy
dokumentów znajdujących się w Serwisie (zalecane: Mozilla Firefox wersja
minimum 90, Opera od wersji 77, Google Chrome <br>
w wersji 90 lub nowszej, MS Intenet Explorer w wersji <span style='color:black;
background:white'>minimum 90 lub nowszej), która zapewnia obsługę plików
cookies oraz skryptów Javascript. Dopuszczalne jest stosowanie innych wersji
przeglądarek internetowych, jeżeli zapewniają one pełną zgodność z wersjami
wymienionymi powyżej.</span></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif'>Usługodawca zastrzega, że
korzystanie z usług świadczonych drogą elektroniczną może wiązać się z ryzykiem
<span style='color:black;background:white'>zainfekowania Systemu teleinformatycznego
Usługobiorcy przez niepożądane oprogramowanie, także takie, którego jedynym
celem jest wyrządzenie szkód. </span></span><span lang=PL style='font-family:
"Arial",sans-serif;color:black'>Usługodawca nie ponosi odpowiedzialności za
ryzyko wiążące się z korzystaniem przez Usługobiorcę z Usług, w szczególności
nie ponosi odpowiedzialności za szkodę nie będącą następstwem jego winy czy
zaniedbania, a także za taką, której wyłączną przyczyną jest działanie
Usługobiorcy lub osób trzecich, za które Usługodawca nie ponosi
odpowiedzialności</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Dla zachowania
bezpieczeństwa korzystania z Serwisu zaleca się, aby urządzenie, <br>
z którego korzysta Usługobiorca, posiadało w szczególności:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>system antywirusowy
z najnowszą wersją definicji wirusów i uaktualnień. Zaleca się, aby program
antywirusowy był stale aktualizowany, niezwłocznie po ukazaniu się aktualizacji
możliwych do zainstalowania,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>skuteczną zaporę
bezpieczeństwa (firewall),</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>zainstalowane wszystkie
dostępne aktualizacje systemu operacyjnego oraz przeglądarki internetowej,
które dotyczą bezpieczeństwa,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>aktywowaną funkcję
akceptacji plików cookies oraz Java Script w przeglądarce internetowej,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>oprogramowanie
umożliwiające odczyt plików w formacie PDF.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługodawca
nie ponosi odpowiedzialności za:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>problemy techniczne
związane z wadliwym funkcjonowaniem posiadanych przez Usługobiorcę Środków
komunikacji elektronicznej oraz </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>brak parametrów
umożliwiających Usługobiorcy odbiór danych.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif'>Usługobiorca
zobowiązany jest do przestrzegania zakazu nadużywania Środków komunikacji
elektronicznej oraz dostarczania przez lub do Systemów teleinformatycznych Usługodawcy
następujących treści: </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif'>powodujących zachwianie pracy
lub przeciążenie Systemów teleinformatycznych Usługodawcy lub innych podmiotów
biorących bezpośredni lub pośredni udział <br>
w świadczeniu usług drogą elektroniczną, </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif'>niezgodnych z powszechnie
obowiązującymi przepisami prawa,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif'>naruszających dobra osób
trzecich, ogólnie przyjęte normy społeczne, moralne lub godzące w dobre
obyczaje; obraźliwe lub naruszające czyjąkolwiek godność. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif'>W
przypadkach szczególnych, mających wpływ na bezpieczeństwo lub stabilność Systemu
teleinformatycznego, Usługodawca ma prawo do czasowego zaprzestania lub
ograniczenia świadczenia Usług, bez wcześniejszego powiadomienia, oraz do
przeprowadzenia prac konserwacyjnych mających na celu przywrócenie
bezpieczeństwa i stabilności Systemu teleinformatycznego.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif'>Usługodawca
nie ponosi odpowiedzialności za przerwy w świadczeniu Usług wynikające <br>
z awarii lub przypadków wadliwego funkcjonowania Systemów teleinformatycznych
będących poza wpływem Usługodawcy. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif'>Niezależnie
od powyższego Usługodawca ma prawo zaprzestać świadczenia Usług <br>
w każdym czasie, jeżeli takie uzasadnione żądanie zostanie zgłoszone przez
dostawcę Internetu lub inny uprawniony podmiot. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-family:"Arial",sans-serif'>Usługodawca
zastrzega sobie prawo do przerwania świadczenia Usług w przypadku stwierdzenia
naruszenia przez Usługobiorcę niniejszego Regulaminu, obowiązującego prawa lub
ogólnie przyjętych norm społecznych i moralnych.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługobiorca ponosi
odpowiedzialność za prawdziwość i aktualność danych podanych Usługodawcy, w tym
zwłaszcza danych podanych w formularzu rejestracyjnym o którym mowa w § 5 ust.
2 Regulaminu (np. adresu e-mail).</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;line-height:normal'><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-top:0cm;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>§
4</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal'><b><span lang=PL
style='font-family:"Arial",sans-serif'>Zawarcie i rozwiązanie umowy o
świadczenie usług drogą elektroniczną</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługodawca świadczy Usługi
na rzecz Usługobiorców w zakresie i na warunkach określonych w niniejszym
Regulaminie.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Każdy Usługobiorca, od
momentu skorzystania z danej Usługi, zobowiązany jest do przestrzegania
postanowień niniejszego Regulaminu, który stanowi integralną część Umów
zawieranych przez Usługodawcę z Usługobiorcami.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Rozpoczęcie korzystania
przez Usługobiorcę z Usługi oznacza zawarcie przez Usługobiorcę z Usługodawcą
umowy o świadczenie usług drogą elektroniczną.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Umowa o świadczenie usług
drogą elektroniczną może zostać rozwiązana przez każdą ze Stron, z
uwzględnieniem postanowień niniejszego Regulaminu.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługobiorca ma prawo
rozwiązać umowę o świadczenie usług drogą elektroniczną poprzez:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>zaniechanie korzystania z
Serwisu,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>rezygnację z usługi Konta
Usługobiorcy poprzez zgłoszenie na adres: info@atabi.pl woli usunięcia Konta
Usługobiorcy i wyrejestrowania tym samym Usługobiorcy <br>
z Serwisu,</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>wypowiedzenie Umowy dostępu
do treści cyfrowych zgodnie z postanowieniami § 6 Regulaminu.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>§ 5</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Rejestracja. Konto Usługobiorcy</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><b><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Zarejestrowanymi
Usługobiorcami mogą być osoby fizyczne, posiadające pełną zdolność do czynności
prawnych, jak również osoby fizyczne posiadające ograniczoną zdolność do
czynności prawnych w zakresie, w jakim zgodnie z obowiązującymi przepisami
prawa mogą nabywać prawa i zaciągać zobowiązania, osoby prawne oraz jednostki
organizacyjne nie posiadające osobowości prawnej.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>W celu dokonania
rejestracji Usługobiorca zobowiązany jest wypełnić formularz rejestracyjny,
obejmujący następujące dane:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>imię i nazwisko lub firmę
(nazwę) Usługobiorcy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>imię Użytkownika,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>adres korespondencyjny lub
adres siedziby Usługobiorcy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>adres e-mail Usługobiorcy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>numer karty kredytowej
Usługobiorcy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>login oraz</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>g)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>hasło,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.3pt;text-align:justify;line-height:normal;background:white'><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>a następnie kliknąć
na link aktywujący wysłany na podany w formularzu rejestracyjnym adres e-mail
Usługobiorcy. Link jest ważny przez 24 godziny od jego wysłania.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>W celu utworzenia
Konta Usługobiorcy konieczne jest nadto wyrażenie przez Usługobiorcę zgody na
przetwarzanie danych osobowych Usługobiorcy oraz złożenie oświadczenia o
akceptacji postanowień niniejszego Regulaminu. W przypadku odmowy wyrażenia
zgody na przetwarzanie danych osobowych Usługobiorcy w zakresie, w jakim są one
wymagane do zawarcia i realizacji Umowy, Usługodawca uprawniony jest do odmowy
jej zawarcia z Usługobiorcą.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługobiorca,
poprzez wysłanie wypełnionego i zawierającego odpowiednie odznaczenia formularza
rejestracyjnego, oświadcza, że:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>podane w formularzu
rejestracyjnym dane są kompletne, zgodne ze stanem faktycznym i nie naruszają
jakichkolwiek praw osób trzecich,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>jest uprawniony do zawarcia Umowy,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>jest uprawniony do
utworzenia Konta Usługobiorcy zgodnie z ust. 1 niniejszego paragrafu,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>zapoznał się z Regulaminem i
zobowiązuje się go przestrzegać, a także</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:35.45pt;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>wyraża zgodę na
przetwarzanie danych osobowych o której mowa w ust. 3 powyżej.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Dla Usługobiorcy, który
wysłał formularz rejestracyjny, Usługodawca utworzy unikalne Konto
Usługobiorcy, wyróżniające się adresem poczty elektronicznej, którego nazwa
odpowiadać będzie loginowi podanemu przez Usługobiorcę w formularzu
rejestracyjnym. Możliwe jest utworzenie tylko jednego Konta Usługobiorcy z
wykorzystaniem danego adresu e-mail oraz tylko jednego Konta Usługobiorcy z
wykorzystaniem danego loginu.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługodawca może
odmówić utworzenia Konta Usługobiorcy o określonym loginie, jeżeli dany login
zarejestrowany został już na ten sam adres poczty elektronicznej lub jeżeli
poweźmie uzasadnione podejrzenie, że jest on sprzeczny z prawem, dobrymi
obyczajami lub narusza dobra osobiste osób trzecich. Usługobiorca zostanie
niezwłocznie poinformowany przez Usługodawcę na podany przez niego w formularzu
rejestracyjnym adres poczty elektronicznej o odmowie utworzenia Konta
Usługobiorcy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługobiorca
uzyskuje dostęp do Konta Usługobiorcy za pomocą unikalnego identyfikatora,
odpowiadającego jego loginowi oraz podanego przez Usługobiorcę hasła dostępu.
Dane dostępowe do Konta Usługobiorcy mogą być wykorzystywane wyłącznie przez
Usługobiorcę lub wskazanego przez niego Użytkownika. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługobiorca nie
jest uprawniony do ujawnienia danych dostępowych jakiejkolwiek osobie trzeciej
innej niż wskazany przez Usługobiorcę podczas rejestracji Użytkownik i jest
odpowiedzialny za szkody wyrządzone wskutek nieuprawnionego ujawnienia danych
dostępowych osobie trzeciej.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Za pomocą Konta
Usługobiorcy, Usługobiorca ma możliwość korzystania z Usługi dostępu do treści
cyfrowych, modyfikacji danych podanych przy rejestracji, modyfikacji danych
osobowych oraz modyfikacji ustawień Konta Usługobiorcy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługobiorca będący
konsumentem może odstąpić od umowy o prowadzenie Konta Usługobiorcy bez podania
przyczyn i bez ponoszenia kosztów, składając stosowne oświadczenie w terminie
14 dni od daty zawarcia Umowy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Do
zachowania terminu o którym mowa w ust. 11 powyżej wystarczające jest wysłanie
oświadczenia przed upływem terminu na jego złożenie na adres Usługobiorcy
podany <br>
we wstępie do niniejszego Regulaminu lub na adres e-mail podany w § 4 ust. 5
lit. b Regulaminu.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>W razie odstąpienia od umowy
o prowadzenie Konta Usługobiorcy, uważana jest ona za niezawartą.</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-top:0cm;
text-align:center;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif;
color:#424242'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>§
6 <br>
Usługa dostępu do treści cyfrowych</span></b></p>

<p style='margin:0cm'><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>W
przypadku Usługobiorców będących konsumentami, Umowa dostępu do treści
cyfrowych zawarta może zostać: na okres próbny wynoszący 15 dni lub na czas określony
jednego, trzech, sześciu lub dziewięciu miesięcy. O ile przy zawieraniu umowy
dostępu do treści cyfrowych na czas określony nie zaznaczono wyraźnie inaczej,
nie ulega ona automatycznemu przedłużeniu na kolejny okres.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>W
przypadku Usługobiorców nie będących konsumentami, Umowa dostępu do treści
cyfrowych zawierana jest na okres próbny wynoszący 15 dni lub na czas
nieokreślony.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>Usługobiorca
będący konsumentem może odstąpić od umowy dostępu do treści cyfrowych bez
podania przyczyn, składając stosowne oświadczenie w terminie 14 dni od daty
zawarcia Umowy, z zastrzeżeniem postanowień ust. 4 poniżej.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:black;background:white'>Prawo odstąpienia od umowy dostępu do treści
cyfrowych jako od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość
nie przysługuje Usługobiorcy będącemu konsumentem w odniesieniu do umów o
dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym,
jeżeli Usługobiorca będący konsumentem rozpoczął już </span><span lang=PL
style='font-size:11.0pt;font-family:"Arial",sans-serif'>korzystanie z
zakupionej usługi dostępu do treści cyfrowych.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><span lang=PL style='font-family:"Arial",sans-serif'>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Do
zachowania terminu o którym mowa w ust. 3 powyżej wystarczające jest wysłanie
oświadczenia przed upływem terminu na jego złożenie na adres Usługobiorcy
podany <br>
we wstępie do niniejszego Regulaminu lub na adres e-mail podany w § 4 ust. 5
lit. b Regulaminu.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>W
razie odstąpienia od Umowy dostępu do treści cyfrowych uważana jest ona za
niezawartą. Zwrot dokonanej przez Usługobiorcę będącego konsumentem płatności nastąpi
nie później niż w terminie 14 dni. Zwrot nastąpi za pośrednictwem tej samej
formy, jaką została dokonana dana płatność, chyba że Usługobiorca uzgodni z Usługodawcą
inną formę realizacji zwrotu.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>Usługobiorca
nie będący konsumentem może wypowiedzieć umowę z zachowaniem miesięcznego
okresu wypowiedzenia, ze skutkiem na koniec miesiąca, poprzez przesłanie wypowiedzenia
w formie elektronicznej na adres: info@atabi.pl lub w formie tradycyjnej na
adres siedziby Usługodawcy wskazany we wstępie do Regulaminu.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>Usługa
dostępu do treści cyfrowych w okresie próbnym jest nieodpłatna. Po upływie
okresu próbnego, Usługa płatna jest zgodnie z <u>Cennikiem </u>dedykowanym
danej kategorii Usługobiorców. Ceny podane w Cenniku uwzględniają podatek VAT w
wysokości wynikającej z powszechnie obowiązujących przepisów prawa.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>Uruchomienie
dostępu do opłaconej Usługi następuje po otrzymaniu przez Usługodawcę
potwierdzenia wpłynięcia należności od pośrednika płatności, bądź zaksięgowaniu
wpłaty na rachunku bankowym Usługodawcy.</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>W
przypadku opłat abonamentowych dostęp do Usługi dostępu do treści cyfrowych
blokowany jest po upływie opłaconego okresu abonamentu (okresu, na który
wykupiony został dostęp do treści cyfrowych objętych Usługą).  </span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify;text-indent:-21.3pt'><span lang=PL style='font-size:11.0pt;
font-family:"Arial",sans-serif'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>Zawierając
Umowę dostępu do treści cyfrowych Usługobiorca dokonuje wyboru metody płatności
za Usługę. Dostępne metody płatności to: płatność kartą kredytową Visa, lub Mastercard,
przelew na konto bankowe albo <span style='color:black;background:white'>skorzystanie
z szybkiego przelewu <br>
w systemie Przelewy24 lub BLIK.</span></span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;
text-align:justify'><span lang=PL style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-top:0cm;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'>§ 7<br>
</span></b><b><span lang=PL style='font-family:"Arial",sans-serif'>Reklamacje</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal'><b><span lang=PL style='font-family:"Arial",sans-serif;
background:white'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Wszelkie reklamacje
dotyczące Usług świadczonych przez Usługodawcę Usługobiorca może składać w
formie pisemnej, za pomocą listu poleconego na adres Usługodawcy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Reklamacja musi zawierać:
imię, nazwisko i adres korespondencyjny oraz adres poczty mailowej
Usługobiorcy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Reklamacje będą rozpatrywane
w terminie 14 dni od dnia otrzymania listu poleconego przez Usługodawcę.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługodawca może żądać
dodatkowych informacji, jeżeli okaże się to konieczne do ustalenia faktów i
rozpatrzenia reklamacji.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługodawca rozpatrując
reklamację stosować będzie postanowienia Regulaminu.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>O decyzji Usługodawcy
Usługobiorca zostanie powiadomiony pisemnie na adres pocztowy lub drogą
elektroniczną na adres e-mail podany w liście poleconym zawierającym
reklamację.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>§ 8</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Przetwarzanie danych
osobowych</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Usługodawca
zbiera i przetwarza dane osobowe przekazane przez Usługobiorcę w ramach
korzystania z usług wymienionych w § 2 Regulaminu, będąc w takiej sytuacji
administratorem danych osobowych. Szczegółowe informacje dotyczące
przetwarzania danych osobowych zawarte są na podstronie internetowej </span><span
lang=PL style='color:black'><a
href="https://www.kokolingo.pl-pl/ochrona-danych-osobowych"><span
style='font-family:"Arial",sans-serif;background:white'>https://www.kokolingo.pl-pl/ochrona-danych-osobowych</span></a></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>&nbsp;
w dokumencie o nazwie „Polityka prywatności”.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><span
lang=PL style='font-family:"Arial",sans-serif;color:black'><br>
<b>§ 9</b></span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Zmiany Regulaminu</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Usługodawca uprawniony jest
do wprowadzania zmian Regulaminu </span><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'>z ważnych przyczyn technicznych, prawnych lub
organizacyjnych, w szczególności:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>gdy zmiana
Regulaminu jest konieczna ze względu na zmianę przepisów powszechnie
obowiązującego prawa,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>w przypadku
zmiany sposobu świadczenia Usług,</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-align:justify;text-indent:-14.15pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>w celu
realizacji obowiązku wynikającego z powszechnie obowiązujących przepisów prawa
lub obowiązku wynikającego z prawomocnego orzeczenia sądu lub prawomocnej decyzji
administracyjnej.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>W przypadku wprowadzenia
zmian Regulaminu Usługodawca powiadamia Usługobiorców o zmianie Regulaminu </span><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>z
30-dniowym wyprzedzeniem </span><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>przed wejściem w życie tych zmian.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Powiadomienie, o
którym mowa powyżej, będzie mieć formę wiadomości e-mail.&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Zmiany Regulaminu
wchodzą w życie w dniu podanym przez Usługodawcę.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Usługobiorca może wypowiedzieć
umowę o świadczenie Usługi w związku <br>
z wprowadzanymi zmianami w Regulaminie w terminie 30 dni od dnia otrzymania
powiadomienia, o którym mowa powyżej. Niewypowiedzenie umowy przez Usługobiorcę
<br>
w tym terminie oznacza akceptację przez Usługobiorcę wszystkich zmian
Regulaminu.&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><strong><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>§ 10</span></strong></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><strong><span
lang=PL style='font-family:"Arial",sans-serif;color:black;background:white'>Postanowienia
końcowe</span></strong></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:8.0pt;
text-align:center;line-height:normal;background:white'><strong><span lang=PL
style='font-family:"Arial",sans-serif;font-weight:normal'>&nbsp;</span></strong></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:#202020;background:white'>Usługobiorca
będący konsumentem może skorzystać z pozasądowych sposobów rozpatrywania
reklamacji i dochodzenia roszczeń i może uzyskać dostęp do tych procedur
kontaktując się z powiatowymi (miejskimi) rzecznikami konsumentów,
organizacjami społecznymi, do których zadań statutowych należy ochrona
konsumentów, Wojewódzkimi Inspektorami Inspekcji Handlowej oraz Urzędem Ochrony
Konkurencji <br>
i Konsumentów.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:#202020;background:white'>W
przypadku Usługobiorców niebędących konsumentami, sądem właściwym do
rozpatrywania sporów związanych z Regulaminem jest sąd właściwy dla siedziby Usługodawcy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>W kwestiach
nieuregulowanych niniejszym Regulaminem stosuje się przepisy prawa polskiego.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Niniejszy
dokument stanowi regulamin świadczenia usług drogą elektroniczną <br>
w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
elektroniczną.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black;background:white'>Regulamin
wchodzi w życie dnia 03.09.2021 roku.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;line-height:normal;
background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'><br>
<br>
</span></p>

</div>
`,
    }}
  ></div>
);
