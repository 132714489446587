import * as React from 'react';

import {
  BackToTopButton,
  Title,
  BlogTitle,
  BlogSubtitle,
  BlogControls,
  BlogCredits,
  BlogLink,
} from '../../components';

import { Page, BlogArticles } from '..';

import routes from '../../routes';

export default () => {
  return (
    <Page
      title="KOKOLINGO – Ćwiczenia logopedyczne on-line"
      description="Ćwiczenia Kokolingo, dostępne on-line, mają strukturę
      opartą na standardowej procedurze terapii logopedycznej,
      wspierającej prawidłową wymowę głosek."
    >
      <div className="educational-corner-page">
        <Title
          variant="dark"
          className="educational-corner-page__title no-margin"
        >
          KĄCIK EDUKACYJNY KOKOLINGO
        </Title>
        <div className="educational-corner__container">
          <div className="educational-corner__blog blog">
            <div>
              <BlogTitle>ĆWICZENIA LOGOPEDYCZNE ON-LINE</BlogTitle>
              <p>
                Logopeda to wykwalifikowany specjalista zajmujący się terapią
                zaburzeń mowy i języka. Zazwyczaj zajęcia z terapii mowy i
                języka przeprowadza się stacjonarnie, jednak możliwe są też
                ćwiczenia zdalne za pomocą. W trakcie terapii logopeda lub
                terapeuta ma do dyspozycji szereg materiałów, takich jak
                zabawki, zgadywanki, arkusze pracy i ćwiczeń, w zależności od
                stopnia zaburzenia, z którym dziecko się mierzy.{' '}
                <b>
                  Najczęściej spotykanym rodzajem zaburzeń są zaburzenia wymowy
                </b>
                , czyli trudności w artykulacji poszczególnych głosek. Zazwyczaj
                przejawia się to elizją głosek, substytucją głosek, lub
                zakłóceniami i zaburzeniami artykulacji, skutkującymi
                niepoprawną wymową.{' '}
                <BlogLink
                  title="Aplikacja Kokolingo"
                  src={process.env.GATSBY_APP_URL}
                >
                  Aplikacja Kokolingo
                </BlogLink>{' '}
                została zaprojektowana tak,{' '}
                <b>
                  by wspomagać terapię wad wymowy w oparciu o standardowy model
                  terapii wad mowy, co pozwala skutecznie kształtować prawidłową
                  artykulację głosek
                </b>
                .
              </p>
              <BlogSubtitle>WPROWADZENIE DO APLIKACJI KOKOLINGO</BlogSubtitle>
              <p>
                <b>
                  Ćwiczenia korygujące wymowę głosek prowadzi się w kilku
                  etapach, które mogą się zazębiać
                </b>
                . Na początku ćwiczenia skupiają się na umiejętnościach
                warunkujących prawidłową artykulację: różnicowaniu głosek i
                sprawności narządów mowy. Gdy dziecko posiądzie te podstawowe
                umiejętności, rozpoczyna się etap ćwiczeń związanych z
                prawidłową produkcją głosek bazujący na listach słów i testach
                sylabowych bez określonego sensu słownego, tzw. logatomach. Wraz
                z nabywaniem przez dziecko kolejnych umiejętności potrzebnych do
                prawidłowej wymowy poszczególnych słów i logatomów, zakres
                ćwiczeń poszerza się o dłuższe struktury, zdania i wypowiedzi w
                celu utrwalenia prawidłowej wymowy.{' '}
                <BlogLink
                  title="Aplikacja Kokolingo"
                  src={process.env.GATSBY_APP_URL}
                >
                  Aplikacja Kokolingo
                </BlogLink>{' '}
                działa na bazie takiego schematu.
              </p>
              <BlogSubtitle>RÓŻNICOWANIE GŁOSEK</BlogSubtitle>
              <p>
                Ważne, by przed rozpoczęciem terapii wad wymowy, dziecko
                potrafiło prawidłowo rozróżniać głoski sobie podobne, takie jak{' '}
                <i>xx</i> i <i>yy</i> lub <i>xx</i> i <i>zz</i>.{' '}
                <b>Umiejętność ta jest kluczowa dla prawidłowej wymowy</b>.
                Aplikacja Kokolingo rozpoczyna od ćwiczeń tej właśnie
                umiejętności, w zadaniach pod nazwą Słuchaj uważnie.
                Rozróżnianie głosek można w aplikacji ćwiczyć za pomocą dwóch
                zadań: Poszukiwacze skarbów oraz Kolorowanka. Przykładowo,
                zadnia polegają na wskazaniu, które słowa zawierają głoskę{' '}
                <i>xx</i>, a które głoskę <i>yy</i> -- w ten sposób dziecko uczy
                się rozróżniać podobne głoski. Poszczególnym słowom towarzyszą
                obrazki, które dziecko może kliknąć, by posłuchać prawidłowej
                wymowy słowa lub ćwiczonej głoski, np. głoski <i>xx</i> lub{' '}
                <i>yy</i>. Każde słowo można wielokrotnie odtworzyć. Ćwiczenia
                słuchowe tego typu pozwalają dziecku wykształcić słuch
                fonematyczny.
              </p>
              <BlogSubtitle>SPRAWNY APARAT ARTYLUACYJNY</BlogSubtitle>
              <p>
                <b>
                  By poprawnie wymawiać głoski, dziecko musi sprawnie operować
                  aparatem artykulacyjnym: wargami, żuchwą czy językiem
                </b>
                . By zapewnić sprawność ruchową narządów mowy, zaleca się
                ćwiczenia motoryki narządów artykulacyjnych, swoistą gimnastykę
                narządów mowy. Istnieje wiele ćwiczeń motoryki narządów, np.
                ćwiczenia statyczne, podczas których dziecko powinno utrzymać
                język i wargi w określonej pozycji przez kilka sekund, lub
                ćwiczenia dynamiczne, podczas których dziecko powinno poruszać
                językiem lub wargami w określony sposób.
              </p>
              <p>
                W aplikacji Kokolingo takie ćwiczenia znajdują się w pierwszym
                dziale o nazwie Gimnastyka Pirata -- ponieważ pokazuje je Pirat
                Kazik, a dziecko powtarza zdania po nim. Ćwiczenia te zarówno
                poprawiają sprawność narządów mowy, jak i wzmacniają mięśnie.
                Gimnastykę artykulacyjną należy stosować z umiarem. Zaleca się
                wykonanie kilku zadań w ciągu maksymalnie pięciu minut, aby
                zapobiegać przemęczeniu aparatu artykulacyjnego.
              </p>
              <BlogSubtitle>SYLABY I SŁOWA</BlogSubtitle>
              <p>
                Materiały do ćwiczeń prawidłowej wymowy składają się z listy
                sylab bez przypisanego znaczenia słownego. Listy sylab układa
                specjalista logopeda, a ich zawartość zależy od rodzaju głoski,
                jaką chcemy wyćwiczyć.{' '}
                <b>
                  Listy logatomów tworzy się starannie dobierając zbitki głosek
                  w taki sposób, by nie zawierały głosek, które dzieci często
                  podmieniają
                </b>
                . Przykładowo, by wykształcić głoskę <i>xx</i>, z listy
                logatomów usuwa się słowa zawierające głoskę <i>yy</i> (lub{' '}
                <i>zz</i> czy <i>vv</i>), ponieważ dzieci często stosują te dwie
                głoski zamiennie. Słowa zawierające podobne głoski wprowadza się
                stopniowo, dopiero gdy dziecko przyswoi sobie prawidłową wymowę
                danej głoski w wyrazach bez głosek-zamienników.
              </p>
              <p>
                W Kokolingo listy sylab nazywamy Fikuśnymi słówkami z uwagi na
                ich zabawne i dziwaczne brzmienie. Logatomy można ćwiczyć w
                dwóch zadaniach: Wspinaczce i Ukrytych obrazach. W tego typu
                zadaniach dziecko kształtuje wymowę jednego logatomu (np.{' '}
                <i>xxx</i> lub <i>yyy</i>) lub pary logatomów (np.{' '}
                <i>xx – xx</i> lub <i>yy – yy</i>). Listy słówek stanowią
                najpopularniejszą metodę kształtowania wymowy, dlatego
                stworzyliśmy aż trzy zadania tego typu: Fiszki, Znajdź słowa i
                Grę Memory. Listy słów w tych zadaniach są tak układane, by
                ćwiczyć daną głoskę w każdej pozycji: na początku, w środku i na
                końcu wyrazu. To sprawia, że dziecko stopniowo nabywa prawidłową
                wymowę danej głoski – rozpoczynając od najłatwiejszej pozycji
                głoski w wyrazach, krok po kroku przechodzi do bardziej
                wymagających pozycji. Ten typ zadań nie tylko koryguje wymowę,
                ale pozwala też dłużej utrzymać uwagę i skupienie oraz stanowi
                doskonały trening pamięci.
              </p>
              <BlogSubtitle>ZDANIA</BlogSubtitle>
              <p>
                Przy pomocy sylab i słów kształtujemy prawidłową wymowę w
                odizolowanych, małych strukturach językowych.{' '}
                <b>
                  Za pomocą zdań, dziecko może utrwalić prawidłową wymowę w
                  dłuższych strukturach, rozpoczynając od prostych zdań, by
                  następnie przejść do pracy z dłuższymi i bardziej złożonymi
                  wypowiedziami
                </b>
                . Ćwicząc wymowę zdań, kształtujemy prawidłową wymowę głosek w
                trakcie swobodnej wypowiedzi. Aplikacja Kokolingo zawiera cztery
                zadania ukierunkowane na automatyzację prawidłowej wymowy, z
                tego dwa zadania na zdaniach – Koło fortuny i Fiszki -- oraz dwa
                zadania bazujące na opowiadaniu -- Poczytaj ze mną oraz Poczytaj
                i pomyśl. Ćwiczenia ułożone są progresywnie, od krótkich,
                prostych zdań do dłuższych, bardziej złożonych wypowiedzi.
                Jeżeli dziecko ma trudności z zapamiętaniem dłuższych zdań,
                można je podzielić na mniejsze fragmenty, tak by nie zniechęcać
                a motywować dziecko do pracy.
              </p>
              <p>
                By terapia wad wymowy przyniosła oczekiwane skutki, potrzebna
                jest systematyczność, konsekwencja i motywacja do pracy, a
                poszczególne zadania muszą sprawiać dziecku radość – w
                przeciwnym wypadku zarzuci ono ich wykonywanie. Aplikacja
                Kokolingo zapewnia systematyczność pracy, monitoruje postępy,
                motywuje do ćwiczeń i bawi.{' '}
                <b>
                  Ze względu na cyfrowy format, aplikacja Kokolingo świetnie
                  nadaje się dla dzieci, które niechętnie wykonują ćwiczenia na
                  bazie papierowych materiałów czy rzeczywistych przedmiotów
                </b>
                . Dzięki niej praca terapeuty i ćwiczenia z rodzicami w domu są
                łatwiejsze i, co ważniejsze, stanowią element zabawy dla
                dziecka.
              </p>
              <BlogCredits author="Krešimir Perša, logopeda i terapeuta mowy." />
            </div>
            <BlogControls
              nextPage={routes.DISTANCE_SPEECH_THERAPY}
              prevPage={routes.SPEECH_DEVELOPMENT}
            />
          </div>
          <BlogArticles />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};
