import React from 'react';
import HubspotForm from 'react-hubspot-form';

export default () => (
  <HubspotForm
    portalId="25066339"
    formId="a883867d-5b74-484b-96e6-13d29a6f17d5"
    locale="pl"
    region="eu1"
  />
);
