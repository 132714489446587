import * as React from 'react';

import {
  BackToTopButton,
  Title,
  BlogImage,
  BlogTitle,
  BlogSubtitle,
  BlogControls,
} from '../../components';

import { Page, BlogArticles } from '..';

import routes from '../../routes';

import game from '../../images/pl/igra.png';
import child from '../../images/pl/dijete2.png';

const DistanceSpeechTherapy = () => {
  return (
    <Page
      title="KOKOLINGO – Zdalne wsparcie terapii wad wymowy"
      description="Diagnozę i terapię logopedyczną prowadzi wykwalifikowany
      specjalista – logopeda lub neurologopeda. Ćwiczenia logopedyczne
      zwykle prowadzone są na żywo, w gabinecie logopedycznym, możne
      je jednak prowadzić również zdalnie, za pomocą narzędzi do
      wideokonferencji (więcej na ten temat przeczytacie Państwo w
      części drugiej „Ćwiczeń logopedycznych on-line”) lub specjalnych
      programów logopedycznych (takich jak Kokolingo)."
    >
      <div className="educational-corner-page">
        <Title
          variant="dark"
          className="educational-corner-page__title no-margin"
        >
          KĄCIK EDUKACYJNY KOKOLINGO
        </Title>
        <div className="educational-corner__container">
          <div className="educational-corner__blog blog">
            <div>
              <BlogTitle>ZDALNE WSPARCIE TERAPII WAD WYMOWY</BlogTitle>
              <p>
                Diagnozę i terapię logopedyczną prowadzi wykwalifikowany
                specjalista – logopeda lub neurologopeda. Ćwiczenia logopedyczne
                zwykle prowadzone są na żywo, w gabinecie logopedycznym, możne
                je jednak prowadzić również zdalnie, za pomocą narzędzi do
                wideokonferencji (więcej na ten temat przeczytacie Państwo w
                części drugiej „Ćwiczeń logopedycznych on-line”) lub specjalnych
                programów logopedycznych (takich jak Kokolingo). Najczęstszym
                wyzwaniem z jakim spotykają się logopedzi w pracy z dziećmi są
                wady wymowy, dyslalie. Podczas terapii logopedycznej
                wykorzystywane są różne materiały, dobrane do wieku, potrzeb i
                możliwości Pacjenta. W przypadku dzieci w wieku przedszkolnym i
                wczesnoszkolnym kluczowe do utrzymania motywacji oraz uwagi jest
                takie dobieranie materiałów logopedycznych, które będą
                atrakcyjne dla uczestnika terapii logopedycznej. Dzięki temu
                proces terapii może odbywać się poprzez zabawę! W zależności od
                przyczyn oraz celu terapii można korzystać z puzzli, klocków,
                zabawek, pacynek, kart pracy, materiałów plastycznych,
                biurowych, czy też znalezionych podczas spaceru liści, kamyków,
                szyszek, płatków kwiatów… Wyobraźnia i kreatywność logopedów nie
                mają granic, a dzięki temu dzieci chętnie wykonują zadania –
                terapia logopedyczna odbywa się przez starannie zaplanowaną
                zabawę.
              </p>
              <BlogSubtitle>
                WSPÓŁPRACA RODZIC-DZIECKO-SPECJALISTA (LOGOPEDA)
              </BlogSubtitle>
              <p>
                Powodzenie terapii logopedycznej zależy od ścisłej współpracy na
                linii Rodzic (Opiekun) – Specjalista (Logopeda/Neurologopeda).
                Tym większą ma ta współpraca rolę w sytuacji prowadzenia terapii
                logopedycznej on-line. Opiekunowie powinni czuć się pewnie jako
                bezpośrednie wsparcie procesu terapii w domu i mieć dostęp do
                indywidualnych wskazówek oraz nadzorowania terapii przez
                logopedę. Warto precyzyjnie zaplanować kolejne zajęcia i omówić
                je z podopiecznymi, aby mogli przygotować wspólnie materiały,
                wygospodarować czas i przestrzeń w środowisku domowym, tak aby
                spotkanie z logopedą za pośrednictwem platformy takiej jak
                Skype, Google Meets czy Teams, było efektywne i przebiegło w
                spokojnej atmosferze. Do codziennej pracy z dzieckiem w domu i
                do pracy z logopeda podczas terapii warto wybrać materiały,
                które pozwolą Opiekunom w sposób efektywny i poprawny
                metodycznie wspierać dziecko w terapii logopedycznej.
              </p>
              <BlogImage imgSrc={game} imgAlt="Game" imgTitle="Game" />
              <BlogSubtitle>
                STRUKTURA ĆWICZEŃ LOGOPEDYCZNYCH KOKOLINGO, WSPIERAJĄCYCH
                TERAPIĘ LOGOPEDYCZNĄ ON-LINE
              </BlogSubtitle>
              <p>
                Ćwiczenia mające na celu uzyskanie prawidłowej artykulacji
                wykonywane są w kilku fazach, które mogą się nakładać. Na
                początku ćwiczeń nacisk kładziony jest na warunki niezbędne do
                prawidłowej wymowy: słuchowe różnicowanie głosek, prawidłowy tor
                oddechowy i usprawnianie aparatu artykulacyjnego. Ćwiczenia
                oddechowe, artykulacyjne i na różnicowanie głosek powinny być
                powtarzane regularnie, stanowią one podstawę do dalszej terapii
                logopedycznej. Kolejne zadania (przedstawione dla dzieci w
                formie zabaw i gier) oparte są o występowanie danej głoski w
                sylabach oraz w parach sylab. Dzięki tym aktywnościom, łatwiej
                jest dziecku opanować prawidłową wymowę danej głoski w myśl
                zasady stopniowania trudności. Kiedy dziecko opanuje poprawną
                wymowę w obrębie sylab, kolejne ćwiczenia (znajdujące się na
                poszczególnych wyspach archipelagu Kokolingo) zawierają
                korygowaną głoskę w materiale słownym, a następnie w zdaniach
                zawierających te słowa oraz opowieściach, mających na celu
                generalizację nabytej umiejętności – prawidłowej wymowy głoski!
                Prawidłowe wymawianie głoski należy zautomatyzować, używając
                dłuższych struktur językowych, czyli zdań i opowiadań. Ta
                sekwencja ćwiczeń jest dostępna w aplikacji Kokolingo. Co bardzo
                istotne z punktu widzenia Opiekunów, każda głoska przeczytana
                jest prawidłowo przez lektora, znanego i uwielbianego Janusza
                Zadurę, który to również wciela się w rolę Pirata Kazika i
                prowadzi w formie krótkich filmów ćwiczenia oddechowe i
                artykulacyjne. Sylaby, słowa, zdania i opowiadania czytane są
                przez lektorkę, logopedę Katarzynę Łuszczak. W ten sposób
                Opiekun nie musi sam wypowiadać danej głoski, jeśli nie jest
                przekonany co do poprawności własnej wymowy – a o tym często
                wspominają Rodzice. Każda głoska: w izolacji, sylabach, słowach,
                jest wypowiadana prawidłowo, tak by dziecko miało możliwość
                wielokrotnych powtórzeń i odsłuchania głoski.
              </p>
              <BlogSubtitle>ĆWICZENIA ODDECHOWE I ARTYKULACYJNE</BlogSubtitle>
              <p>
                W terapii logopedycznej wad wymowy ćwiczenia oddechowe i
                artykulacyjne są podstawą każdego spotkania z logopedą, a także
                ćwiczeń w warunkach domowych. Dla dzieci to świetna zabawa, jej
                cel jednak jest istotnym krokiem do prawidłowej artykulacji i
                muszą być wykonywane prawidłowo. W Kokolingo codziennie można
                posłuchać Pirata Kazika zachęcającego dzieci do kolejnych
                ćwiczeń oddechowych i artykulacyjnych. Do każdego ćwiczenia
                można wrócić wielokrotnie! Ćwiczenia oddechowe i ćwiczenia
                artykulacyjne są przygotowane dla każdej głoski. Charyzmatyczny
                i uroczy Pirat Kazik zamienia żmudne powtarzanie w niesamowitą
                przygodę!
              </p>
              <BlogSubtitle>RÓŻNICOWANIE GŁOSEK</BlogSubtitle>
              <p>
                Zanim dziecko rozpocznie ćwiczenia logopedyczne mające na celu
                poprawną wymowę, ważne jest, aby nauczyło się rozróżniać podobne
                dźwięki, takie jak dźwięki S i Z. Słuchowe różnicowanie dźwięków
                jest warunkiem prawidłowej wymowy. W przypadku ćwiczeń w
                aplikacji logopedycznej Kokolingo te aktywności znajdują się na
                pierwszych wyspach i mają postać dwóch rodzajów ćwiczeń:
                Zbierania skarbów i Dopasowania kolorów. W ten sposób dziecko
                uczy się rozróżniać bardzo podobne dźwięki, na przykład
                konieczne jest ustalenie, w jakich słowach słyszy dźwięk K, a w
                jakich słowach głoskę G. Słowa w tych aktywnościach prezentowane
                są za pomocą wyraźnych obrazków oraz nagrania. Nagranie każdej
                głoski i słowa można powtórzyć wiele razy. W ten sposób dziecko
                ćwiczy uwagę słuchową i uczy się rozróżniać podobne głoski.
              </p>
              <BlogImage imgSrc={child} imgAlt="Child" imgTitle="Child" />
              <BlogSubtitle>SYLABY I SŁOWA</BlogSubtitle>
              <p>
                Listy i pary sylab są starannie przygotowane przez zespół
                specjalistów logopedii. Sylaby zawierają głoski w różnych
                pozycjach: w nagłosie, śródgłosie i wygłosie. Sylaby możemy
                ćwiczyć w dwóch aktywnościach, Wspinaczka i Odkrywanie obrazków.
                W tych czynnościach dziecko powinno wymówić albo tylko jedną
                sylabę, na przykład SA lub TRA, albo wymówić parę sylab, na
                przykład SA-SE lub TRA-TRI. Ponieważ poprawną wymowę ćwiczy się
                głównie za pomocą list słów, opracowano dla nich trzy ćwiczenia
                – Karty do pogadanki, Znajdź słowa i Memory. Te ćwiczenia
                zawierają różne listy słów, gdzie głoska występuje na początku
                słowa, w środku lub na końcu. W ten sposób dziecko
                systematycznie trenuje poprawną wymowę określonej głoski, od
                pozycji, w których najłatwiej uzyskać poprawną wymowę, do tych,
                w których uzyskanie poprawnej wymowy jest nieco trudniejsze.
                Oprócz korygowania wymowy za pomocą tych czynności, poprawiamy
                również uwagę i koncentrację oraz ćwiczymy pamięć. Program
                logopedyczny Kokolingo nie tylko wspiera terapię logopedyczną,
                ale też stymuluje rozwój funkcji poznawczych i umiejętności
                szkolnych!
              </p>
              <BlogSubtitle>ZDANIA I OPOWIEŚCI</BlogSubtitle>
              <p>
                Przy pomocy sylab i słów dziecko uczy się poprawnej w mniejszych
                jednostkach językowych. Tylko za pomocą zdań dziecko
                zautomatyzuje poprawną wymowę w większych jednostkach,
                zaczynając od krótszych zdań, a następnie ćwicząc w dłuższych i
                bardziej złożonych zdaniach. Ćwicząc wymowę w zdaniach,
                pracujemy nad tym, aby dziecko prawidłowo wymawiało określoną
                głoskę w mowie spontanicznej. Program logopedyczny Kokolingo
                zawiera cztery rodzaje aktywności, które automatyzują poprawną
                wymowę. Są to dwie aktywności zawierające zadania zdaniowe –
                Koło fortuny i Uzupełnianie oraz dwie aktywności zawierające
                zadania fabularne – Poczytajmy razem i Poczytajmy i pomyślmy.
                Zadania te tworzą logiczną sekwencję, od krótszych i prostszych
                zdań po dłuższe i bardziej złożone w opowiadaniach. Oczywiście,
                jeśli dziecko nie powtarza długich zdań, możemy podzielić je na
                dwa lub trzy krótsze fragmenty zdania, ponieważ nie chcemy, aby
                dziecko było sfrustrowane, ale zmotywowane do pracy. Pamiętajmy,
                żeby zawsze w razie wątpliwości zapytać o radę logopedę! Dzięki
                opisanym w kolejnej części artykułu „Ćwiczenia logopedyczne
                on-line” sposobom, nadzór logopedy nad przebiegiem ćwiczeń w
                domu jest możliwy zdalnie! Ćwicząc poprawną wymowę musimy być
                systematyczni, konsekwentni i zmotywowani do pracy. Oczywiście,
                aby dziecko wykonało zaplanowane czynności, zadania muszą być
                przede wszystkim zabawne i motywujące. Aplikacja logopedyczna
                Kokolingo łączy systematyczną pracę, monitorowanie postępów,
                motywatory i zabawne zajęcia. Ponieważ jest to narzędzie
                cyfrowe, ten „zeszyt ćwiczeń” jest idealny dla dzieci, które
                trudno zmotywować do pracy z materiałami drukowanymi. Ułatwia
                pracę terapeutyczną logopedom, rodzicom ćwiczenia w domu, a co
                najważniejsze – jest zabawą dla dzieci. W terapii logopedycznej
                on-line Kokolingo stanowi doskonałą platformę do współpracy
                Rodzic (Opiekun) – Logopeda, dzięki czemu nasz Podopieczny
                otrzymuje najlepsze wsparcie w drodze do prawidłowej wymowy!
                Ruszamy ku logopedycznej przygodzie!
              </p>
            </div>
            <BlogControls
              nextPage={routes.PRESCHOOLER_AND_SPEECH_THERAPY}
              prevPage={routes.ONLINE_SPEECH_THERAPY_EXERCISES}
            />
          </div>
          <BlogArticles />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default DistanceSpeechTherapy;
