const paths = require('./paths');

paths.PARENTS_ANCHOR = '#cennik-dla-rodzica';
paths.FACILITIES_ANCHOR = '#cennik-dla-instytucji';
paths.PRIVACY_POLICY = 'polityka-prywatności';
paths.TOS = 'warunki-korzystania-z-usług';
paths.KOKOLINGO_GIFT = 'podaruj-kokolingo';
paths.FAQ = 'faqs';
paths.INSTITUTIONS = 'instytucji';
paths.PARTNERS = 'nasi-partnerzy';
paths.MEDIA = 'media-o-nas';

paths.EDUCATIONAL_CORNER = 'kącik-edukacyjny';
paths.DISTANCE_SPEECH_THERAPY =
  'kącik-edukacyjny/zdalne-wsparcie-terapii-wad-wymowy';
paths.ONLINE_SPEECH_THERAPY_EXERCISES =
  'kącik-edukacyjny/ćwiczenia-logopedyczne-on-line';
paths.PRESCHOOLER_AND_SPEECH_THERAPY =
  'kącik-edukacyjny/przedszkolak-i-logopedia';
paths.SPEECH_DEVELOPMENT = 'kącik-edukacyjny/rozwój-mowy';
paths.SPEECH_DISORDER = 'kącik-edukacyjny/czym-są-wady-wymowy-dyslalie';

module.exports = paths;
