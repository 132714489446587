import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Tag } from '../../components';

export default () => {
  return (
    <>
      <Row>
        <Col>
          {['CH', 'TH', 'L', 'R', 'VocR'].map((sound) => (
            <Tag>{sound}</Tag>
          ))}
        </Col>
      </Row>
      <Row>
        <Tag>L Blends</Tag>
        <Col>
          {['CH', 'TH', 'L', 'R', 'VocR'].map((sound) => (
            <Tag variant="light">{sound}</Tag>
          ))}
        </Col>
      </Row>
      <Row>
        <Tag>R Blends</Tag>
        <Col>
          {['CH', 'TH', 'L', 'R', 'VocR'].map((sound) => (
            <Tag variant="light">{sound}</Tag>
          ))}
        </Col>
      </Row>
      <Row>
        <Tag>S Blends</Tag>
        <Col>
          {['CH', 'TH', 'L', 'R', 'VocR'].map((sound) => (
            <Tag variant="light">{sound}</Tag>
          ))}
        </Col>
      </Row>
    </>
  );
};
