import React from 'react';

export default () => (
  <div
    className="container"
    dangerouslySetInnerHTML={{
      __html: `
  <style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:0cm;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
\t{mso-style-link:"Footer Char";
\tmargin:0cm;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
\t{color:blue;
\ttext-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:0cm;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:0cm;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
\t{margin-top:0cm;
\tmargin-right:0cm;
\tmargin-bottom:8.0pt;
\tmargin-left:36.0pt;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
span.FooterChar
\t{mso-style-name:"Footer Char";
\tmso-style-link:Footer;}
.MsoChpDefault
\t{font-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{margin-bottom:8.0pt;
\tline-height:107%;}
 /* Page Definitions */
 @page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 ol
\t{margin-bottom:0cm;}
ul
\t{margin-bottom:0cm;}
-->
</style>


<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-top:0cm;
text-align:center;line-height:normal;background:white'><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>POLITYKA PRYWATNOŚCI</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:0cm;
margin-top:0cm;text-align:center;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>(ZWANA DALEJ
POLITYKĄ)</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><b><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><b><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Polityka obejmuje informacje
dotyczące przetwarzania danych osobowych i innych dotyczących Państwa informacji
oraz określa zasady przetwarzania danych osobowych pozyskanych przez
Administratora za pośrednictwem Serwisu.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Polityka opisuje działania
podejmowane przez Administratora w zakresie Państwa danych osobowych,
pozyskanych za pośrednictwem Serwisu oraz powiązanych z Serwisem usług <br>
i narzędzi, wykorzystywanych przez Państwa do wykonywania czynności związanych <br>
z funkcjonalnościami Serwisu, w tym w szczególności takich czynności, jak
zakładanie Konta Usługobiorcy.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Pojęciom występującym w
Polityce, rozpoczynającym się dużą literą, przypisane jest znaczenie zgodne z
ich definicjami zawartymi w Regulaminie świadczenia usług drogą elektroniczną
przez ATABI Katarzyna Łuszczak. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Administrator traktuje
wszystkie dane osobowe jako poufne i przetwarza je zgodnie <br>
z </span><span lang=PL style='font-family:"Arial",sans-serif;color:black;
background:white'>rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679
z dnia 27 kwietnia <br>
2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
osobowych <br>
i w sprawie swobodnego przepływu takich danych oraz ustawą o ochronie danych
osobowych z dnia 10 maja 2018 roku</span><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><b><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>ADMINISTRATOR DANYCH
OSOBOWYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><a name="_Hlk64632729"><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Administratorem danych
osobowych jest </span></a><span lang=PL style='font-family:"Arial",sans-serif;
color:black;background:white'>Katarzyna Łuszczak, prowadząca jednoosobową
działalność gospodarczą pod firmą ATABI Katarzyna Łuszczak z siedzibą w
Poznaniu <br>
(62-288), os. Czecha 52/2, NIP: 7822209209, REGON: 363904111</span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>, zwana dalej <b>Administratorem</b>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Z Administratorem można
skontaktować się w następujący sposób:</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>korespondencyjnie, na adres
siedziby Administratora,</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>mailowo, na adres: info@atabi.pl</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-indent:-21.3pt;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>CEL GROMADZENIA DANYCH OSOBOWYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Administrator zbiera i przetwarza Państwa dane osobowe w celu
udostępnienia funkcjonalności Serwisu działającego pod adresem </span><span
lang=PL style='color:black'><a href="http://www.kokolingo.pl"><span
style='font-family:"Arial",sans-serif'>www.kokolingo.pl</span></a></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'> i zaoferowania Państwu
najlepszej możliwej obsługi poprzez wygodny dostęp do usług oferowanych przez
Administratora, w tym w szczególności usługi dostępu do treści cyfrowych.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>RODZAJE
PRZETWARZANYCH DANYCH OSOBOWYCH I PODSTAWA ICH PRZETWARZANIA</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-indent:-21.3pt;line-height:normal;background:white'><b><span
lang=PL style='font-family:"Arial",sans-serif'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>WIZYTA NA STRONIE INTERNETOWEJ </span></b><span lang=PL
style='color:black'><a href="http://WWW.KOKOLINGO.PL"><b><span
style='font-family:"Arial",sans-serif'>WWW.KOKOLINGO.PL</span></b></a></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>W momencie odwiedzenia przez Państwa Serwisu zbierane są
automatycznie ogólne informacje <a name="_Hlk64633148">mające na celu
zapewnienie pełnej funkcjonalności i bezpieczeństwa Systemu</a>
teleinformatycznego. Zbierane informacje obejmują informacje o: przeglądarce,
systemie operacyjnym, nazwie domeny dostawcy Internetu, adresie IP komputera,
stronie internetowej, przez którą zostali Państwo przekierowani do Serwisu
(HTTP referer), odwiedzanych przez Państwa podstronach Serwisu, a także dacie i
długości Państwa wizyty. Informacje te mają formę spseudonimizowaną, przez co
ich przyporządkowanie do Państwa jest niemożliwe.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRZETWARZANIE DANYCH
OSOBOWYCH PRZY ZAŁOŻENIU KONTA USŁUGOBIORCY</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Państwa
dane osobowe są zbierane i dalej przetwarzane w trakcie założenia Konta Usługobiorcy
na podstawie art. 6 ust. 1 lit. b RODO. Przetwarzaniu podlegają Państwa dane
osobowe podane w poszczególnych polach formularza rejestracyjnego, zbierane w
celu zawarcia i realizacji <a name="_Hlk64634439">Umowy</a>. Po realizacji Umowy
lub usunięciu Konta Usługobiorcy, Państwa dane osobowe zostaną zarchiwizowane na
okres wynikający z powszechnie obowiązujących przepisów prawa, a po jego
upływie usunięte, jeśli w tym czasie nie została przez Państwa wyrażona
jednoznaczna zgoda na dalsze ich przetwarzanie</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;line-height:
normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-indent:-21.3pt;line-height:normal'><b><span
lang=PL style='font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=PL style='font-family:"Arial",sans-serif'>PRZEKAZYWANIE
DANYCH OSOBOWYCH DO DOSTAWCÓW USŁUG PŁATNOŚCI</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Administrator
współpracuje ze spółką pod firmą PayPro S.A., ul. Kanclerska 15, 60-327 Poznań
(zwaną dalej <b>&quot;PayPro&quot;</b>), będącą dostawcą usługi szybkiego
przelewu w systemie Przelewy24. Po wybraniu powyższej metody płatności, Państwa
dane osobowe zostaną automatycznie przesłane do PayPro, na podstawie art. 6
ust. 1 lit. a RODO (zgoda) oraz <br>
art. 6 ust. 1 lit. b RODO (przetwarzanie w celu realizacji Umowy). </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Dane
osobowe przekazywane PayPro to zazwyczaj dane, które zostały przekazane
Administratorowi przy zawieraniu Umowy, takie jak, przykładowo: imię i
nazwisko, adres zamieszkania, adres korespondencyjny, adres e-mail, numer
rachunku płatniczego, w tym rachunku bankowego, numer karty płatniczej albo
karty kredytowej lub inny identyfikator użytego instrumentu płatniczego, numer
telefonu oraz adres IP. Przekazanie danych osobowych następuje tylko w takim
zakresie, w jakim jest to niezbędne do przetworzenia płatności.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Polityka prywatności PayPro
jest dostępna na stronie:</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>https://www.przelewy24.pl/obowiazek-informacyjny-platnik</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;line-height:
normal'><b><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>USUWANIE DANYCH
OSOBOWYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Państwa dane osobowe zostaną usunięte lub zarchiwizowane, gdy
tylko cel ich przetwarzania zostanie osiągnięty lub gdy zgłoszone zostanie
żądanie ich usunięcia. Dane osobowe są również usuwane z upływem określonego
dla nich przepisami prawa okresu przechowywania, chyba że zachodzi potrzeba
dalszego ich przechowywania, w celu zawarcia Umowy lub jej realizacji albo
została przez Państwa wyrażona zgoda na dalsze ich przechowywanie.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>PLIKI COOKIES</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>Aby
korzystanie ze stron internetowych było atrakcyjne dla ich użytkowników,
stosowane są pliki cookies. Są to pliki tekstowe ulokowane na dysku twardym
danego użytkownika strony internetowej, w celu umożliwienia identyfikacji
przeglądarki po ponownym uruchomieniu strony internetowej. Po zamknięciu
przeglądarki sesyjne pliki cookies zostają usunięte.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Poprzez zmianę ustawień
przeglądarki możliwe jest zapobieżenie zapisywaniu plików cookies na dysku
twardym. Zapisane pliki cookies można usunąć w dowolnym momencie. W celu
usunięcia plików cookies lub uniemożliwienia ich przechowywania, należy
zapoznać się <br>
z instrukcją danej przeglądarki. Należy jednak pamiętać, że wskutek odmowy
używania plików cookies, korzystanie z Serwisu może być utrudnione.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>PRAWA OSOBY BĘDĄCEJ
PODMIOTEM DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>W przypadku przetwarzania Państwa danych osobowych stają się Państwo
podmiotem danych w rozumieniu RODO i przysługują Państwu następujące prawa:</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO INFORMACJI</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Mogą Państwo wnieść prośbę o potwierdzenie, czy Państwa dane
osobowe są przetwarzane przez Administratora, a jeśli ma to miejsce, mogą
Państwo zażądać dostępu do nich oraz informacji o:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>celach, w których Państwa
dane osobowe są przetwarzane,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>kategoriach Państwa
danych osobowych, które są przetwarzane,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>odbiorcach lub
kategoriach odbiorców, którym Państwa dane osobowe zostały ujawnione lub są
nadal ujawniane,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>planowanym okresie
przechowywania Państwa danych osobowych lub, jeśli nie jest to możliwe, o
kryteriach jego ustalenia,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>prawie do żądania
sprostowania lub usunięcia Państwa danych osobowych, do ograniczenia
przetwarzania Państwa danych osobowych lub prawie do sprzeciwu wobec takiego
przetwarzania,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>prawie wniesienia
przez Państwa skargi do organu nadzorczego,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>źródłach danych,
jeżeli dane osobowe nie zostały zebrane od Państwa,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>zautomatyzowanym
podejmowaniu decyzji, w tym o profilowaniu oraz, jeśli uprzednie dotyczy, o
zasadach ich podejmowania, a także o znaczeniu i przewidywanych konsekwencjach
takiego przetwarzania dla Państwa.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO POPRAWIENIA DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Przysługuje Państwu prawo do żądania niezwłocznego poprawienia
i/lub uzupełnienia danych osobowych, jeśli dane osobowe przetwarzane przez
Administratora są nieprawidłowe lub niekompletne.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO OGRANICZENIA
PRZETWARZANIA DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>W następujących przypadkach mogą Państwo żądać ograniczenia
przetwarzania Państwa danych osobowych:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>jeśli przetwarzanie
jest niezgodne z prawem, a sprzeciwiają się Państwo usunięciu danych osobowych
żądając w zamian ograniczenia ich wykorzystywania,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>jeśli Administrator
nie potrzebuje już Państwa danych osobowych do celów przetwarzania, ale są one
Państwu potrzebne do ustalenia, dochodzenia lub obrony Państwa roszczeń;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>jeśli wyrazili
Państwo sprzeciw wobec przetwarzania Państwa danych zgodnie z art. 21 <br>
ust. 1 RODO i nie zostało jeszcze stwierdzone, czy prawnie uzasadnione podstawy
po stronie Administratora są nadrzędne wobec podstaw Państwa sprzeciwu. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Jeśli przetwarzanie Państwa
danych osobowych zostało ograniczone, dane te mogą być przetwarzane, z
wyjątkiem przechowywania, wyłącznie za Państwa zgodą lub w celu ustalenia,
dochodzenia lub obrony roszczeń albo ochrony praw innej osoby fizycznej lub
prawnej lub przez wzgląd na interes publiczny na terenie Unii Europejskiej lub
państwa członkowskiego. <a name="_Hlk65144574">Zostaną Państwo poinformowani, z
wyprzedzeniem, o uchyleniu ograniczenia przetwarzania Państwa danych osobowych.</a></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO USUNIĘCIA DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Mogą Państwo zażądać, aby Administrator niezwłocznie usunął
Państwa dane osobowe, przy czym Administrator zobowiązany jest usunąć wszelkie
Państwa dane osobowe, jeśli spełniony jest jeden z następujących warunków:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Państwa dane osobowe
nie są już niezbędne do celów, dla których zostały zebrane lub przetworzone w
inny sposób,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>cofnęli Państwo
zgodę na przetwarzanie danych udzieloną zgodnie z art. 6 ust. 1 lit. a lub art.
9 ust. 2 lit. a RODO i nie ma innej podstawy prawnej do ich przetwarzania,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>wnieśli Państwo
sprzeciw wobec przetwarzania Państwa danych osobowych na podstawie art. 21 ust.
1 RODO i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania lub
wnieśli Państwo sprzeciw na podstawie art. 21 ust. 2 RODO,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Państwa dane osobowe
były przetwarzane niezgodnie z prawem,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>usunięcie Państwa
danych osobowych jest wymagane w celu wypełnienia zobowiązania prawnego na mocy
prawa unijnego lub krajowego, któremu podlega Administrator,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>Państwa dane osobowe
zostały zebrane w związku z oferowaniem usług społeczeństwa informatycznego, o
których mowa w art. 8 ust 1 RODO.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Prawo do usunięcia danych
osobowych nie przysługuje w zakresie, w jakim przetwarzanie jest niezbędne:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>w celu korzystania z
prawa do wolności wypowiedzi i informacji,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>w celu wywiązania
się z obowiązku prawnego wymagającego przetwarzania na mocy prawa Unii
Europejskiej lub prawa państwa członkowskiego, któremu podlega Administrator,
lub w celu wykonania zadania leżącego w interesie publicznym lub w ramach
wykonywania władzy publicznej powierzonej Administratorowi, </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>ze względu na
interes publiczny w dziedzinie zdrowia publicznego zgodnie z art. 9 ust. 2 <br>
lit. h oraz lit. i, a także w art. 9 ust. 3 RODO,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>do ustalenia,
dochodzenia lub obrony roszczeń.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>Jeżeli Administrator
upublicznił Państwa dane osobowe, a na mocy art. 17 ust. 1 RODO ma obowiązek te
dane osobowe usunąć, to – biorąc pod uwagę dostępną technologię <br>
i koszt realizacji – Administrator podejmie rozsądne działania, w tym środki
techniczne, by poinformować administratorów przetwarzających Państwa dane
osobowe o Państwa żądaniu, tak by usunęli oni wszelkie łącza do tych danych,
kopie tych danych osobowych lub ich replikacje.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO INFORMACJI O DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>O sprostowaniu lub usunięciu Państwa danych osobowych lub
ograniczeniu ich przetwarzania, w związku ze zgłoszonymi przez Państwa
żądaniami, Administrator poinformuje każdego odbiorcę, któremu Państwa dane
osobowe zostały ujawnione, chyba że okaże się to niemożliwe lub będzie wymagać
niewspółmiernie dużego wysiłku. Na Państwa żądanie Administrator poinformuje
Państwa o tych odbiorcach.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO PRZENOSZENIA DANYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Przysługuje Państwu prawo do otrzymania, w ustrukturyzowanym,
powszechnie używanym formacie nadającym się do odczytu maszynowego, dotyczących
Państwa danych osobowych, które udostępnili Państwo Administratorowi oraz prawo
do żądania przesłania tych danych osobowych innemu administratorowi, jeżeli:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>przetwarzanie odbywa
się na podstawie zgody o której mowa w art. 6 ust. 1 lit. a RODO lub art. 9
ust. 2 lit. a RODO lub na podstawie umowy, w myśl art. 6 ust. 1 lit. b RODO
oraz</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:normal;
background:white'><span lang=PL style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>przetwarzanie odbywa
się w sposób zautomatyzowany.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO SPRZECIWU</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>W przypadku przetwarzania Państwa danych osobowych w oparciu o
art. 6 ust. 1 lit. e lub <br>
lit. f RODO, z przyczyn związanych z Państwa szczególną sytuacją, mają Państwo
prawo do wyrażenia, w dowolnym momencie, sprzeciwu wobec przetwarzania Państwa
danych osobowych. W przypadku wniesienia przez Państwa sprzeciwu Administrator nie
będzie już mógł przetwarzać Państwa danych osobowych, chyba że będzie w stanie
wykazać, iż posiada prawnie uzasadnione podstawy do ich przetwarzania,
nadrzędne wobec Państwa interesów, praw i wolności lub podstawy do ustalenia,
dochodzenia lub obrony roszczeń. Jeśli Państwa dane osobowe były przetwarzane
wyłącznie na potrzeby marketingu bezpośredniego, mają Państwo prawo do
wyrażenia, w dowolnym momencie, sprzeciwu wobec przetwarzania Państwa danych na
potrzeby działań marketingowych. W przypadku wniesienia przez Państwa sprzeciwu
wobec przetwarzania Państwa danych osobowych do celów marketingu
bezpośredniego, Państwa dane osobowe nie będą już mogły być do takich celów
przetwarzane.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal;background:white'><span lang=PL
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO COFNIĘCIA ZGODY NA PRZETWARZANIE
DANYCH OSOBOWYCH</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>W każdej chwili mogą Państwo cofnąć oświadczenie o wyrażeniu zgody
na zbieranie <br>
i przetwarzanie Państwa danych osobowych. Cofnięcie zgody nie ma jednak wpływu
na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed
jej wycofaniem.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>7.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=PL
style='font-family:"Arial",sans-serif;color:black'>PRAWO DO ZŁOŻENIA SKARGI W
ORGANIE NADZORUJĄCYM</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Mają Państwo prawo wnieść skargę do organu nadzorczego, w
szczególności do Prezesa Urzędu Ochrony Danych Osobowych, jeżeli w Państwa
ocenie przetwarzanie przez Administratora Państwa danych osobowych narusza
Państwa interesy.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>ZAUTOMATYZOWANE
PODEJMOWANIE DECYZJI</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Państwa dane osobowe nie podlegają zautomatyzowanemu podejmowaniu
decyzji. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;text-align:
justify;line-height:normal;background:white'><span lang=PL style='font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:normal;
background:white'><b><span lang=PL style='font-family:"Arial",sans-serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=PL style='font-family:"Arial",sans-serif;color:black'>ZMIANY W NINIEJSZEJ
POLITYCE PRYWATNOŚCI</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
line-height:normal;background:white'><span lang=PL style='font-family:"Arial",sans-serif;
color:black'>Administrator zastrzega sobie prawo do zmian w niniejszej Polityce
ze skutkiem na przyszłość, spowodowanych w szczególności zmianami w powszechnie
obowiązujących przepisach prawa lub koniecznością dostosowania Polityki do
wewnętrznych procesów Administratora. Aktualna wersja Polityki jest w każdym
czasie dostępna w Serwisie. Administrator prosi <br>
o regularnie odwiedzanie Strony i zapoznawanie się z obowiązującymi przepisami
dotyczącymi ochrony danych osobowych. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;line-height:normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;line-height:
normal'><span lang=PL style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

`,
    }}
  ></div>
);
