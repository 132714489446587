import React from 'react';
import { Link } from 'gatsby';

import routes from '../../routes';

export default [
  <Link to={routes.SPEECH_DISORDER} hrefLang="pl">
    Czym są wady wymowy?
  </Link>,

  <Link to={routes.SPEECH_DEVELOPMENT} hrefLang="pl">
    Rozwój mowy
  </Link>,

  <Link to={routes.ONLINE_SPEECH_THERAPY_EXERCISES} hrefLang="pl">
    Ćwiczenia logopedyczne online
  </Link>,

  <Link to={routes.DISTANCE_SPEECH_THERAPY} hrefLang="pl">
    Zdalne wsparcie terapii wad wymowy (część I)
  </Link>,

  <Link to={routes.PRESCHOOLER_AND_SPEECH_THERAPY} hrefLang="pl">
    Przedszkolak i logopedia
  </Link>,
];
