import * as React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';
import { Link } from 'gatsby';

import {
  Title,
  BlogTitle,
  BlogSubtitle,
  BackToTopButton,
  BlogContent,
} from '../../components';

import { Page, HubspotCouponForm } from '..';

import routes from '../../routes';

export default () => {
  const messages = useIntlMessages();

  return (
    <Page
      title={messages.meta.GiftKokolingoTitle}
      description={messages.meta.GiftKokolingoDescription}
    >
      <div className="coupon-page">
        <Title className="coupon-page__title fluid no-margin" variant="dark">
          BON PODARUNKOWY
        </Title>
        <div className="coupon__container">
          <BlogContent className="coupon__blog fluid">
            <div>
              <BlogTitle>
                IDEALNY PREZENT DLA DZIECKA, KTÓRE UCZY SIĘ
                <br />
                PRAWIDŁOWEJ WYMOWY
              </BlogTitle>
              <p>
                Bon podarunkowy Kokolingo to idealny prezent dla dzieci w wieku
                przedszkolnym i wczesnoszkolnym.
                <br />
                Dzięki niemu podarujesz wiatr w żagle Młodemu Odkrywcy, który
                uczy się poprawnej wymowy.
              </p>
              <p>
                Przygoda w towarzystwie Papugi Koko i Pirata Kazika pomoże
                dziecku wykonywać regularnie ćwiczenia w trakcie zabawy, bez
                wysiłku!
              </p>
              <p>
                <b>
                  Dzieci potrzebują czasem wsparcia w rozwoju. To dla Ciebie
                  szansa na bycie bohaterem, który pomoże dziecku z zaburzeniami
                  artykulacji!
                </b>
              </p>
              <BlogSubtitle>
                Jak to wygląda, gdy dziecko otrzymuje bon podarunkowy?
              </BlogSubtitle>
              <p>
                Na wskazany przez Ciebie adres, e-mailem wysyłamy bon w formie
                dokumentu PDF.
              </p>
              <p>
                Możesz przesłać bon do siebie, wydrukować go i osobiście wręczyć
                Odkrywcy!
              </p>
              <BlogSubtitle>Oferta cenowa</BlogSubtitle>
              <p>
                Oferujemy bony podarunkowe Kokolingo <b>Premium</b>,{' '}
                <b>Smart</b> i <b>Mini</b> na korzystanie z Kokolingo przez
                okres <b>1</b>, <b>3</b>, <b>6</b> lub <b>9</b> miesięcy. Opis
                każdego pakietu Kokolingo oraz ceny abonamentu za miesiąc
                użytkowania znajdziesz tutaj:{' '}
                <Link
                  to={routes.PARENTS_ANCHOR}
                  target="_blank"
                  rel="noopener noreferrer"
                  hrefLang="hr"
                >
                  https://www.kokolingo.pl/#cennik-dla-rodzica
                </Link>
              </p>
              <p>
                <b>
                  Kupując bon podarunkowy na kilka miesięcy, otrzymujesz
                  dodatkowe rabaty
                </b>
                : 5% rabatu na bon na 3 miesiące, 10% na 6 miesięcy i 15% na 9
                miesięcy. Zaznacz wybraną opcję w poniższym formularzu.
              </p>
              <BlogSubtitle>Jak złożyć wniosek?</BlogSubtitle>
              <p>
                Jeśli chcesz kupić bon podarunkowy Kokolingo i podarować go
                komuś, wystarczy <b>wypełnić</b> ten krótki <b>formularz</b>, a
                my wyślemy Ci ofertę płatności.
              </p>

              <HubspotCouponForm />

              <h2>
                <Title
                  className="coupon-page__title fluid no-margin"
                  variant="dark"
                >
                  Bon podarunkowy Kokolingo to okazja do wsparcia terapii
                  logopedycznej dziecka i umożliwienia mu postępu w stymulującym
                  środowisku świata Kokolingo, a to wszystko poprzez zabawę.
                  Niech to będzie Twój prezent dla dziecka, wspierający
                  prawidłową wymowę!
                </Title>
              </h2>
              <p>
                Jeśli masz pytania, jesteśmy do Twojej dyspozycji!
                <br />
                <a href="mailto:kokolingo@atabi.pl">kokolingo@atabi.pl</a>
              </p>
            </div>
          </BlogContent>
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};
