import * as React from 'react';

import {
  BackToTopButton,
  Title,
  BlogImage,
  BlogTitle,
  BlogSubtitle,
  BlogControls,
  BlogLiterature,
} from '../../components';

import { Page, BlogArticles } from '..';

import routes from '../../routes';

import child from '../../images/pl/dijete1.png';
import game from '../../images/pl/wheel_of_fortune.png';

const SpeechDisorderPage = () => {
  return (
    <Page
      title="KOKOLINGO - Wady wymowy"
      description="O wadach wymowy (dyslaliach) mówimy kiedy dziecko nie realizuje
                prawidłowo głosek, które, zgodnie z normą rozwojową dla danego
                wieku, powinno realizować."
    >
      <div className="educational-corner-page">
        <Title
          variant="dark"
          className="educational-corner-page__title no-margin"
        >
          KĄCIK EDUKACYJNY KOKOLINGO
        </Title>
        <div className="educational-corner__container">
          <div className="educational-corner__blog blog">
            <div>
              <BlogTitle>CZYM SĄ WADY WYMOWY (DYSLALIE)?</BlogTitle>
              <p>
                O wadach wymowy (dyslaliach) mówimy kiedy dziecko nie realizuje
                prawidłowo głosek, które, zgodnie z normą rozwojową dla danego
                wieku, powinno realizować. Mowa może być wówczas niezrozumiała,
                co powoduje frustrację młodego człowieka. Wady wymowy wynikają z
                wielu przyczyn, zarówno między innymi natury fizjologicznej, jak
                i środowiskowej. Często trudno uchwycić przyczynę wystąpienia
                wady wymowy, jednak zawsze warto pracować nad jej korekcją. W
                realizacji celu podstawę stanowi wnikliwa diagnoza logopedy –
                specjalisty zajmującego się rozwojem mowy. To logopeda prowadzi
                dziecko i rodzinę przez proces terapii.
              </p>
              <p>
                Dzieci uczą się głównie poprzez naśladowanie i pozytywne
                doświadczenia. Nigdy nie strofujmy dziecka, nie mówmy „źle
                mówisz”, nie powtarzajmy błędnej wymowy! Mówmy poprawnie,
                normalnym tonem i głośnością, zachęcajmy do zabaw językowych,
                wspólnego czytania i opowiadania, parafrazujmy nieprawidłowo
                wypowiedziane zdania dokonując natychmiastowej korekcji i
                modelowania prawidłowej realizacji głoski.
              </p>
              <BlogSubtitle>
                Czas spędzony z dzieckiem na wspieraniu jego drogi do
                prawidłowej wymowy to niezwykły czas dla Was!
              </BlogSubtitle>
              <p>
                Należy pamiętać, że pojawienia się głosek nie należy
                przyspieszyć – mają one swój czas i miejsce w rozwoju. Dlaczego?
                Realizacja głosek wymaga określonej sprawności motorycznej
                narządów artykulacyjnych, a także prawidłowego słuchu. Zarówno
                ćwiczenia oddechowe, artykulacyjne, jak i słuchowe mają kluczowe
                znaczenie w rozwoju mowy. Przyspieszanie, wymuszanie artykulacji
                głoski do której realizacji aparat artykulacyjny dziecka nie
                jest przygotowany może być jednym z czynników wpływających na
                jej błędną realizację, a także, przede wszystkim, może powodować
                frustrację u dziecka oraz demotywować do ćwiczeń logopedycznych.
              </p>
              <BlogImage
                imgSrc={game}
                imgAlt="Game - Wheel of Fortune"
                imgTitle="Wheel of Fortune"
              />
              <BlogSubtitle>
                Jak możemy więc sklasyfikować wady wymowy, nazywane w
                literaturze przedmiotu dyslaliami?
              </BlogSubtitle>
              <p>
                Wady wymowy możemy klasyfikować ilościowo (ile głosek jest
                realizowanych nieprawidłowo) oraz jakościowo (jakiego rodzaju są
                to wady wymowy).
              </p>
              <p>
                Termin „dyslalia” wywodzi się z foniatrii i od ponad stu lat
                określa wadę wymowy, a więc jedno z najczęstszych zaburzeń mowy.
                Jak wspomniano wyżej, przyczyny mogą być różnorakie i
                wielorakie, pochodzenia wewnętrznego lub zewnętrznego, w tym
                nieprawidłowości w budowie lub funkcjonowaniu aparatu
                artykulacyjnego (m.in. mózgowe porażenie dziecięce, choroby
                genetyczne, porażenia nerwów, osłabienie mięśni,
                niepełnosprawność intelektualna, zaburzenia słuchu,
                nadwrażliwość sensoryczna), niedosłuch lub zaburzenia słuchu,
                deprywacja sensoryczna w obrębie aparatu artykulacyjnego, brak
                prawidłowych wzorców wymowy oraz inne przyczyny środowiskowe.
              </p>
              <p>Najczęściej występujące wady wymowy to:</p>
              <div>
                <ul>
                  <li>
                    <b>Rotacyzm (reranie)</b> – nieprawidłowa realizacja głoski{' '}
                    <b>/r/</b>
                  </li>
                  <li>
                    <b>Sygmatyzm (seplenienie)</b> – nieprawidłowa realizacja
                    głosek szeregu przedniojęzykowo-dziąsłowego{' '}
                    <b className="text-nowrap">/sz, ż, cz, dż/</b>,
                    przedniojęzykowo-zębowego{' '}
                    <b className="text-nowrap">/s, z, c, dz/</b>, a także
                    środkowojęzykowego{' '}
                    <b className="text-nowrap">/ś, ź, ć, dź/</b>
                  </li>
                  <li>
                    <b>Lamdacyzm (lelanie)</b> – nieprawidłowa realizacja głoski{' '}
                    <b className="text-nowrap">/l/</b>, najczęściej realizowanej
                    w tym przypadku jako <b className="text-nowrap">/j/</b>{' '}
                    czego przyczyną najczęściej jest brak pionizacji języka
                  </li>
                  <li>
                    <b>Kappacyzm (kekanie)</b> – zamiana głoski{' '}
                    <b className="text-nowrap">/k/</b> na{' '}
                    <b className="text-nowrap">/t/</b>
                  </li>
                  <li>
                    <b>Gammacyzm (geganie)</b> – zamiana głoski{' '}
                    <b className="text-nowrap">/g/</b> na{' '}
                    <b className="text-nowrap">/d/</b>
                  </li>
                </ul>
              </div>
              <p>
                Stosowanych jest wiele klasyfikacji zaburzeń mowy o charakterze
                dyslalii. Powyższy tekst ma na celu przybliżenie tematu wad
                wymowy i wskazanie najczęściej występujących dyslalii. Logopedia
                jest dynamiczną dyscypliną naukową, dzięki czemu możemy nadal
                poszukiwać najbardziej użytecznej w celach terapeutycznych,
                funkcjonalnej klasyfikacji. Najważniejsze jest bowiem takie
                sklasyfikowanie zaburzenia, aby jego diagnoza wskazywała
                najbardziej efektywną drogę terapii.
              </p>
              <BlogLiterature>
                <ul>
                  <li>Demelowa G., 1979, Elementy logopedii, Warszawa</li>
                  <li>Pruszewicz A., 1992, Foniatria kliniczna, Warszawa</li>
                  <li>Styczek I., 1979, Logopedia, Warszawa</li>
                </ul>
              </BlogLiterature>
              <BlogImage imgSrc={child} imgAlt="Child" imgTitle="Child" />
            </div>
            <BlogControls
              nextPage={routes.SPEECH_DEVELOPMENT}
              prevPage={routes.PRESCHOOLER_AND_SPEECH_THERAPY}
            />
          </div>
          <BlogArticles />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default SpeechDisorderPage;
