import React from 'react';
import { LogoCompany } from '../../../components';
import polishAward from '../../../images/pl/swiat.png';
import attrs from '../../../constants/contacts/attributes';
import './index.css';

const FooterHeader = () => {
  return (
    <div className="row text-center">
      <div className="col-12">
        <img
          loading="lazy"
          src={polishAward}
          alt="polishAward"
          className="polishAward"
        />
        <a href={attrs.company} rel="noopener noreferrer" target="_blank">
          <LogoCompany />
        </a>
      </div>
    </div>
  );
};

export default FooterHeader;
