import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import routes from '../../routes';

import { html } from '../../utils';

import kokoTheParrotPremium from '../../images/premium_papiga_koko.svg';
import kokoTheParrotSmart from '../../images/smart_papiga_koko.svg';
import kokoTheParrotMini from '../../images/mini_papiga_koko.svg';
import kokoTheParrotFacilities25SVG from '../../images/papiga_koko_ustanove_25.svg';
import kokoTheParrotFacilities10SVG from '../../images/papiga_koko_ustanove_10.svg';
import kokoTheParrotFacilities5SVG from '../../images/papiga_koko_ustanove_5.svg';
import kokoTheParrotFacilities25PNG from '../../images/papiga_koko_ustanove_25.png';
import kokoTheParrotFacilities10PNG from '../../images/papiga_koko_ustanove_10.png';
import kokoTheParrotFacilities5PNG from '../../images/papiga_koko_ustanove_5.png';
import priceList from '../../images/pl/cjenik.jpg';
import priceList2 from '../../images/pl/cjenik2.png';

import { BlogImage } from '../../components';
import PricingPackage from './PricingPackage';
import PricingPackageInstitutes from './PricingPackageInstitutes';

const PARENTS = 'roditelji';
const FACILITIES = 'ustanove';
const PricingTable = () => {
  const [activeKey, setActiveKey] = useState(PARENTS);

  useEffect(() => {
    function hashChange() {
      if (window.location.hash === html.anchorUrl(routes.PARENTS_ANCHOR)) {
        setActiveKey(PARENTS);
      } else if (
        window.location.hash === html.anchorUrl(routes.FACILITIES_ANCHOR)
      ) {
        setActiveKey(FACILITIES);
      }
    }

    window.addEventListener('hashchange', hashChange);

    return () => {
      window.removeEventListener('hashchange', hashChange);
    };
  }, []);

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={14} sm={14} md={10} lg={6}>
          <BlogImage
            imgAlt="price list"
            imgTitle="price list"
            imgSrc={priceList}
          />
        </Col>
        <Col xs={14} sm={14} md={10} lg={6}>
          <BlogImage
            imgAlt="price list"
            imgTitle="price list"
            imgSrc={priceList2}
          />
        </Col>
      </Row>
      <Tabs
        activeKey={activeKey}
        onSelect={(key) => {
          setActiveKey(key);
          // eslint-disable-next-line no-restricted-globals
          history.pushState('', document.title, window.location.pathname);
        }}
        className="nav nav-item parent justify-content-center tab-styling"
      >
        <Tab
          eventKey={PARENTS}
          id="nav-tab-parent"
          title={<Localize id="pricing.ParentsTitle" />}
          className="tab-pane"
        >
          <div id={PARENTS} className="fade show">
            <div className="packages-container">
              <p>
                <Localize id="pricing.KidsPacket" />
              </p>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackage
                      packageLabel="Premium"
                      parrotImage={kokoTheParrotPremium}
                    />
                  </div>
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackage
                      packageLabel="Smart"
                      parrotImage={kokoTheParrotSmart}
                    />
                  </div>
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackage
                      packageLabel="Mini"
                      parrotImage={kokoTheParrotMini}
                    />
                  </div>
                </div>
              </div>
              <p className="pricing-text" style={{ paddingBottom: '0' }}>
                <b>
                  <span style={{ color: '#cc200a' }}>**</span>
                </b>{' '}
                <Localize id="pricing.IndexMotivationDescription" />
              </p>
            </div>
            <div className="packages-container-mobile">
              <p className="pricing-text tab">
                <Localize id="pricing.KidsPacket" />
              </p>
              <Tabs
                defaultActiveKey="premium"
                className="justify-content-center tab-styling"
                style={{ height: '39.5px' }}
              >
                <Tab
                  eventKey="premium"
                  id="premium"
                  title="PREMIUM"
                  className="tab-pane"
                  flex-wrap="nowrap"
                >
                  <div id="premium" className="nav-link fade in active show">
                    <PricingPackage
                      packageLabel="Premium"
                      parrotImage={kokoTheParrotPremium}
                    />
                    <p className="pricing-text" style={{ padding: '2% 4%' }}>
                      <b>
                        <span style={{ color: '#cc200a' }}>**</span>
                      </b>{' '}
                      <Localize id="pricing.IndexMotivationDescription" />
                    </p>
                  </div>
                </Tab>
                <Tab
                  eventKey="smart"
                  id="smart"
                  title="SMART"
                  className="tab-pane"
                >
                  <div id="smart" className="nav-link fade in active show">
                    <PricingPackage
                      packageLabel="Smart"
                      parrotImage={kokoTheParrotSmart}
                    />
                    <p className="pricing-text" style={{ padding: '2% 4%' }}>
                      <b>
                        <span style={{ color: '#cc200a' }}>**</span>
                      </b>{' '}
                      <Localize id="pricing.IndexMotivationDescription" />
                    </p>
                  </div>
                </Tab>
                <Tab
                  eventKey="mini"
                  id="mini"
                  title="MINI"
                  className="tab-pane"
                >
                  <div id="mini" className="nav-link fade in active show">
                    <PricingPackage
                      packageLabel="Mini"
                      parrotImage={kokoTheParrotMini}
                    />
                    <p className="pricing-text" style={{ padding: '2% 4%' }}>
                      <b>
                        <span style={{ color: '#cc200a' }}>**</span>
                      </b>{' '}
                      <Localize id="pricing.IndexMotivationDescription" />
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey={FACILITIES}
          id="nav-tab-facility"
          title={<Localize id="pricing.FacilitiesTitle" />}
        >
          <div id={FACILITIES} className="fade show">
            <div className="packages-container">
              <p>
                <Localize id="pricing.FacilitiesDescription" />
              </p>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackageInstitutes
                      packageLabel="Facilities25"
                      parrotImage={kokoTheParrotFacilities25SVG}
                    />
                  </div>
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackageInstitutes
                      packageLabel="Facilities10"
                      parrotImage={kokoTheParrotFacilities10SVG}
                    />
                  </div>
                  <div className="col-md-4 block4-pricing-container">
                    <PricingPackageInstitutes
                      packageLabel="Facilities5"
                      parrotImage={kokoTheParrotFacilities5SVG}
                    />
                  </div>
                </div>
              </div>

              <p style={{ paddingBottom: '0' }} className="pricing-text">
                <b className="red-bold">*</b>{' '}
                <Localize id="pricing.OneTimePayment" />
              </p>
              <p
                style={{ paddingBottom: '0', paddingTop: '0' }}
                className="pricing-text"
              >
                <b className="red-bold">**</b>{' '}
                <Localize id="pricing.Empolyees" />
              </p>
              <p
                style={{ paddingBottom: '0', paddingTop: '0' }}
                className="pricing-text"
              >
                <Localize
                  id="pricing.Contact"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </p>
            </div>
            <div className="packages-container-mobile">
              <p className="pricing-text tab">
                <Localize id="pricing.FacilitiesDescription" />
              </p>
              <Tabs
                defaultActiveKey="koko25"
                className="justify-content-center tab-styling"
                style={{ height: '39.5px' }}
              >
                <Tab
                  eventKey="koko25"
                  id="koko25"
                  title="KOKO 25"
                  className="tab-pane"
                  flex-wrap="nowrap"
                >
                  <div id="koko25" className="nav-link fade in active show">
                    <PricingPackageInstitutes
                      packageLabel="Facilities25"
                      parrotImage={kokoTheParrotFacilities25PNG}
                      mobile
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="koko10"
                  id="koko10"
                  title="KOKO 10"
                  className="tab-pane"
                  flex-wrap="nowrap"
                >
                  <div id="koko10" className="nav-link fade in active show">
                    <PricingPackageInstitutes
                      packageLabel="Facilities10"
                      parrotImage={kokoTheParrotFacilities10PNG}
                      mobile
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="koko5"
                  id="koko5"
                  title="KOKO 5"
                  className="tab-pane"
                  flex-wrap="nowrap"
                >
                  <div id="koko5" className="nav-link fade in active show">
                    <PricingPackageInstitutes
                      packageLabel="Facilities5"
                      parrotImage={kokoTheParrotFacilities5PNG}
                      mobile
                    />
                  </div>
                </Tab>
              </Tabs>
              <p
                style={{ paddingTop: '5%', paddingBottom: '0' }}
                className="pricing-text tab"
              >
                <b className="red-bold">*</b>{' '}
                <Localize id="pricing.OneTimePayment" />
              </p>
              <p
                style={{ paddingBottom: '0', paddingTop: '0' }}
                className="pricing-text tab"
              >
                <b className="red-bold">**</b>{' '}
                <Localize id="pricing.Empolyees" />
              </p>
              <p
                style={{ paddingTop: '0', paddingBottom: '5%' }}
                className="pricing-text tab"
              >
                <Localize id="pricing.Contact" />
              </p>
            </div>
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default PricingTable;
