import * as React from 'react';

import {
  BackToTopButton,
  Title,
  BlogTitle,
  BlogControls,
} from '../../components';

import { Page, BlogArticles } from '..';

import routes from '../../routes';

export default () => {
  return (
    <Page
      title="KOKOLINGO - Rozwój mowy"
      description="W przebiegu rozwoju mowy mogą pojawić się
      trudności z wymową głosek. Ćwiczenia w aplikacji logopedycznej
      Kokolingo zaprojektowane są po to, aby wspomóc prawidłową wymowę."
    >
      <div className="educational-corner-page">
        <Title
          variant="dark"
          className="educational-corner-page__title no-margin"
        >
          KĄCIK EDUKACYJNY KOKOLINGO
        </Title>
        <div className="educational-corner__container">
          <div className="educational-corner__blog blog">
            <div>
              <BlogTitle>ROZWÓJ MOWY</BlogTitle>
              <p>
                Każde dziecko rozwija się indywidualnie! To wielka przygoda
                obserwować rozwój młodego człowieka! Jest on wyjątkowy i, jak
                doskonale wiemy, nie wolno porównywać dzieci między sobą.
              </p>
              <p>
                W wyniku badań i obserwacji wyodrębniono jednak kolejność
                pojawiania się głosek i ramy czasowe, kiedy ich realizacja
                najczęściej powinna być prawidłowa. Zakłada się, że opóźnienie
                pojawienia się danej głoski o więcej niż 6 miesięcy nie jest
                normą rozwojową i należy skonsultować się z logopedą.
              </p>
              <p>
                Do specjalisty warto udać się również zawsze, kiedy nie mamy
                pewności co do przebiegu rozwoju mowy i kiedy cos budzi nasz
                niepokój!
              </p>
              <p>
                Prawidłowa kolejność pojawiania się głosek w języku polskim jest
                następująca:
              </p>
              <div>
                <ul>
                  <li>
                    <b>/P, B, M, PI, BI, MI/</b> - do ukończenia{' '}
                    <b>1 roku życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/Ś, Ź, Ć, DŹ, N, Ń, J, T, D/</b> - między{' '}
                    <b>1 a 2 rokiem życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/K, G, H, KI, GI, HI/</b> - między{' '}
                    <b>1,5 a 3 rokiem życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/L, Ł/</b> - między <b>2 a 3 rokiem życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/S, Z, C, DZ/</b> - między <b>3 a 4,5 rokiem życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/R/</b> - między <b>4,5 a 6 rokiem życia</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>/SZ, Ż, CZ, DŻ/</b> - między <b>5 a 7 rokiem życia</b>
                  </li>
                </ul>
              </div>
              <p>
                Na każdym etapie formowania głoski mogą wystąpić deformacje,
                zamiany głosek i inne trudności w prawidłowej realizacji– należy
                wspierać ich prawidłową wymowę poprzez modelowanie, nigdy
                poprzez strofowanie dziecka czy zwracanie uwagi!
              </p>
            </div>
            <BlogControls
              nextPage={routes.ONLINE_SPEECH_THERAPY_EXERCISES}
              prevPage={routes.SPEECH_DISORDER}
            />
          </div>
          <BlogArticles />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};
