export default [
  {
    text: 'Magazyn Przedszkola',
    link: 'https://magazynprzedszkola.pl/kokolingo/',
  },
  {
    text: 'edupolis | Aplikacja logopedyczna Kokolingo',
    link: 'https://edupolis.pl/aplikacja-logopedyczna-kokolingo/',
  },
  {
    text:
      'Start-up Challenge - European Tech and Start-up Days | Start-up Challenge - 25 - 27 kwietnia 2022 - Katowice',
    link:
      'https://www.estartupdays.eu/2022/pl/katalog-start-upow/kokolingo,1516.html',
  },
];
