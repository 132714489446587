import React from 'react';

import kokoTheParrotShop1 from './images/shop_main.png';
import kokoTheParrotShop2 from './images/shop_1.png';
import kokoTheParrotShop3 from './images/shop_2.png';

export default [
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop1}
      title="Sklep dla papugi Koko"
      alt="Sklep dla papugi Koko"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop2}
      title="Akcesoria dla papugi Koko"
      alt="Akcesoria dla papugi Koko"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop3}
      title="Akcesoria dla papugi Koko"
      alt="Akcesoria dla papugi Koko"
    />
  </div>,
];
