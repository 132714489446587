export default [
  {
    text:
      'Kokolingo – Kompleksowa aplikacja do terapii logopedycznej dla dzieci z wadami wymowy (Pakiet Premium 25 profili) | Logopedia.pl',
    link:
      'http://logopedia.pl/hurtownia/kokolingo-kompleksowa-aplikacja-do-terapii-logopedycznej-dla-dzieci-z-wadami-wymowy-pakiet-premium-25-profili-kopia/',
  },
  {
    text:
      'Pomoce terapeutyczne, logopedyczne i edukacyjne. Rozwój, terapia i wsparcie z Arante.pl',
    link: 'https://www.arante.pl/product/search?query=kokolingo',
  },
];
