import React from 'react';

import kokolingoIsland from './images/map.png';
import pirateSrecko from './images/articulation-gymnastics.png';
import carefulListeningBeginning from './images/subactivity_2.png';
import treasureHunt from './images/treasure-hunt.png';
import logatomsGame from './images/climb-step-one.png';
import flashCardsStepOne from './images/flash-cards-step-one.png';
import flashCardsStepTwo from './images/flash-cards-step-two.png';
import memoryGame from './images/memory-step-three.png';
import activityBlue from './images/activity-blue.png';
import wheelOfFortune from './images/wheel-of-fortune.png';

export default [
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokolingoIsland}
      title="Interaktywna mapa Kokolingo"
      alt="Interaktywna mapa Kokolingo"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={pirateSrecko}
      title="Pirat Kažika"
      alt="Pirat Kažika"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={carefulListeningBeginning}
      title="Gra w uważne słuchanie"
      alt="Gra w uważne słuchanie"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={treasureHunt}
      title="Poszukiwanie skarbów"
      alt="Poszukiwanie skarbów"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={logatomsGame}
      title="Logatomy"
      alt="Logatomy"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={flashCardsStepOne}
      title="Karty do pogadanky"
      alt="Karty do pogadanky"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={flashCardsStepTwo}
      title="Karty do pogadanky"
      alt="Karty do pogadanky"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={memoryGame}
      title="Memori"
      alt="Memori"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={activityBlue}
      title="Oaza na wyspiee"
      alt="Oaza na wyspie"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={wheelOfFortune}
      title="Koło fortuny"
      alt="Koło fortuny"
    />
  </div>,
];
