import React from 'react';
import HubspotForm from 'react-hubspot-form';

export default () => (
  <HubspotForm
    portalId="25066339"
    formId="ca0dfe09-21d3-4ee8-a66e-7d5adcd57951"
    locale="en"
    region="eu1"
  />
);
