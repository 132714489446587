import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import cn from 'classnames';
import { Localize } from '@kokolingo/localisation';

import routes from '../../routes';

const PricingPackageInstitutes = ({ parrotImage, packageLabel, mobile }) => {
  const smartContainerClassName = cn({
    'pricing-main': packageLabel === 'Facilities10',
  });

  const pricingTitleClassName = cn('pricing-title institutes', {
    'pricing-title-bg': packageLabel !== 'Facilities10',
    smart: packageLabel === 'Facilities10',
  });

  const parrotClassName = cn('pricing-institutes-parrot', {
    smart: packageLabel === 'Facilities10',
  });

  const pricingPriceClassName = cn('pricing-price institutes', {
    smart: packageLabel === 'Facilities10',
  });

  const pricingButtonClassName = cn('mt-2 pricing-button', {
    smart: packageLabel === 'Facilities10',
    institutes: !mobile,
  });

  return (
    <div className="pricing-container institutes">
      <div className={smartContainerClassName}>
        <h1 className={pricingTitleClassName}>
          <Localize id={`pricing.${packageLabel}`} />
        </h1>
        <img
          loading="lazy"
          className={parrotClassName}
          width={mobile ? '170' : '250'}
          height={mobile ? '250' : '290'}
          alt="Papiga Koko"
          src={parrotImage}
        />
        <div className={pricingPriceClassName}>
          <p>
            <b className="red-bold">
              <Localize
                id={`pricing.${packageLabel}Price`}
                values={{
                  span: (valute) => <span>{valute}</span>,
                }}
              />
            </b>
          </p>
        </div>
        <button
          onClick={() => {
            window.open(routes.INSTITUTIONS, '_blank');
          }}
          className={pricingButtonClassName}
          type="button"
        >
          <Localize id="pricing.SearchOffer" />
        </button>
      </div>
      <div className="pricing-text-container">
        <p className="pricing-text institutes">
          <Localize
            id="pricing.FacilitiesPremium"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </p>
        <p className="pricing-text institutes">
          <b className="black-bold">
            <Localize id={`pricing.${packageLabel}NumberOfProfiles`} />
          </b>{' '}
          <Localize id="pricing.FacilitiesProfiles" />
          <b className="red-bold">**</b>
        </p>
        <p className="pricing-text institutes">
          <Localize
            id={`pricing.${packageLabel}WorksOn`}
            values={{
              b: (chunks) => <b className="black-bold">{chunks}</b>,
            }}
          />
        </p>
      </div>
    </div>
  );
};

PricingPackageInstitutes.propTypes = {
  packageLabel: PropTypes.oneOf(['Facilities25', 'Facilities10', 'Facilities5'])
    .isRequired,
  parrotImage: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

PricingPackageInstitutes.defaultProps = {
  mobile: false,
};

export default memo(PricingPackageInstitutes);
